import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { UserRequirementType } from '@youshift/shared/types';

import Toggle from '../../../../components/Toggle';
import { MinMaxCounter } from '../../../../components/MinMaxCounter';
import { Action, RoleConfig, State } from './types';

interface DefineReqsProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  numberOfSlotsSelected: number;
}

export default function DefineReqs({
  state,
  dispatch,
  numberOfSlotsSelected,
}: DefineReqsProps) {
  const { t } = useTranslation();
  const roleReqs = useMemo(
    () =>
      Object.entries(state.roles).reduce(
        (acc, [roleId, { users }]) => {
          // check if req it's the same for all users and if so return this
          const commonMinSlots = users.every(
            user => user.min_slots === users[0].min_slots,
          )
            ? users[0].min_slots
            : // check the case where req is currently NaN for all users (numeric input is blank; possibly because the role numeric input is blank)
              users.every(user => Number.isNaN(user.min_slots))
              ? NaN
              : // if the req is different for at least one user; this is the case where the role numeric input is disabled
                undefined;

          const commonMaxSlots = users.every(
            user => user.max_slots === users[0].max_slots,
          )
            ? users[0].max_slots
            : users.every(user => Number.isNaN(user.max_slots))
              ? NaN
              : undefined;

          const commonMinDuration = users.every(
            user => user.min_duration === users[0].min_duration,
          )
            ? users[0].min_duration
            : users.every(user => Number.isNaN(user.min_duration))
              ? NaN
              : undefined;

          const commonMaxDuration = users.every(
            user => user.max_duration === users[0].max_duration,
          )
            ? users[0].max_duration
            : users.every(user => Number.isNaN(user.max_duration))
              ? NaN
              : undefined;

          acc[roleId] = {
            min_slots: commonMinSlots,
            max_slots: commonMaxSlots,
            min_duration: commonMinDuration,
            max_duration: commonMaxDuration,
          };
          return acc;
        },
        {} as Record<
          string,
          {
            min_slots: number | undefined | typeof NaN;
            max_slots: number | undefined | typeof NaN;
            min_duration: number | undefined | typeof NaN;
            max_duration: number | undefined | typeof NaN;
          }
        >,
      ),
    [state],
  );

  return (
    <div className="px-8">
      <fieldset className="mb-6">
        <legend className="text-sm font-semibold text-gray-900 mb-1">
          {t('manager.rulesConfig.howToConfigureRule')}
        </legend>
        <p className="mb-2 text-xs">
          {t('manager.rulesConfig.slotsSelected', {
            slots: numberOfSlotsSelected,
          })}
        </p>
        <div className="flex gap-3">
          <div className="flex items-center">
            <input
              id="slots"
              name="config-type"
              type="radio"
              checked={state.req_type === 'SLOTS'}
              onChange={() =>
                dispatch({
                  type: 'TOGGLE_REQ_TYPE',
                  value: UserRequirementType.SLOTS,
                })
              }
              className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-teal-600 checked:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
            />
            <label
              htmlFor="slots"
              className="ml-3 block text-sm/6 font-medium text-gray-900"
            >
              {t('manager.rulesConfig.slotsConfig')}
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="hours"
              name="config-type"
              type="radio"
              checked={state.req_type === 'DURATION'}
              onChange={() =>
                dispatch({
                  type: 'TOGGLE_REQ_TYPE',
                  value: UserRequirementType.DURATION,
                })
              }
              className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-teal-600 checked:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
            />
            <label
              htmlFor="hours"
              className="ml-3 block text-sm/6 font-medium text-gray-900"
            >
              {t('manager.rulesConfig.hoursConfig')}
            </label>
          </div>
        </div>
      </fieldset>
      {Object.entries(state.roles).map(
        ([roleId, { users, role_name }], idx) => (
          <Disclosure
            key={roleId}
            defaultOpen={
              !users.every(user => user.included) &&
              !users.every(user => !user.included)
            }
          >
            {({ open }) => (
              <>
                <div className="grid grid-cols-2">
                  <div className="flex flex-row items-center gap-4 border-l-2 border-l-blue-600 pl-3">
                    <Disclosure.Button
                      as="button"
                      className="flex flex-row items-center"
                    >
                      <ChevronRightIcon
                        className={`${open ? 'rotate-90 transform' : ''} w-5 h-5`}
                      />
                    </Disclosure.Button>
                    <Toggle
                      enabled={users.some(user => user.included)}
                      setEnabled={() =>
                        dispatch({
                          type: 'TOGGLE_ROLE',
                          id_role: Number(roleId),
                          payload: {
                            type: users.some(user => user.included)
                              ? 'off'
                              : 'on',
                          },
                        })
                      }
                    />
                    {role_name}
                    {` (${users.length})`}
                  </div>
                  <div className="flex gap-4 mt-3">
                    {state.req_type === UserRequirementType.SLOTS && (
                      <MinMaxCounter
                        min={roleReqs[roleId].min_slots}
                        max={roleReqs[roleId].max_slots}
                        setMin={value =>
                          dispatch({
                            type: 'UPDATE_ROLE_REQS',
                            id_role: Number(roleId),
                            payload: { min_slots: value },
                          })
                        }
                        setMax={value =>
                          dispatch({
                            type: 'UPDATE_ROLE_REQS',
                            id_role: Number(roleId),
                            payload: { max_slots: value },
                          })
                        }
                        minText={
                          idx === 0
                            ? t('manager.rulesConfig.minText')
                            : undefined
                        }
                        maxText={
                          idx === 0
                            ? t('manager.rulesConfig.maxText')
                            : undefined
                        }
                        disabled={
                          roleReqs[roleId].min_slots === undefined ||
                          roleReqs[roleId].max_slots === undefined ||
                          users.some(user => !user.included)
                        }
                      />
                    )}
                    {state.req_type === UserRequirementType.DURATION && (
                      <MinMaxCounter
                        min={roleReqs[roleId].min_duration}
                        max={roleReqs[roleId].max_duration}
                        setMin={value =>
                          dispatch({
                            type: 'UPDATE_ROLE_REQS',
                            id_role: Number(roleId),
                            payload: { min_duration: value },
                          })
                        }
                        setMax={value =>
                          dispatch({
                            type: 'UPDATE_ROLE_REQS',
                            id_role: Number(roleId),
                            payload: { max_duration: value },
                          })
                        }
                        minText={
                          idx === 0
                            ? t('manager.rulesConfig.minHText')
                            : undefined
                        }
                        maxText={
                          idx === 0
                            ? t('manager.rulesConfig.maxHText')
                            : undefined
                        }
                        disabled={
                          roleReqs[roleId].min_duration === undefined ||
                          roleReqs[roleId].max_duration === undefined ||
                          users.some(user => !user.included)
                        }
                      />
                    )}
                  </div>
                </div>
                <Disclosure.Panel
                  as="div"
                  className="grid grid-cols-2 ml-16 mt-3 border-l-2 border-l-blue-600 pl-3 items-center gap-y-4"
                >
                  {[...users]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(user => (
                      <>
                        <div
                          key={user.id_user}
                          className="flex flex-row items-center gap-2"
                        >
                          <Toggle
                            enabled={user.included}
                            setEnabled={() =>
                              dispatch({
                                type: 'TOGGLE_USER',
                                id_user: user.id_user,
                              })
                            }
                          />
                          <p>{user.name}</p>
                        </div>
                        <div className="flex gap-4">
                          {state.req_type === UserRequirementType.SLOTS && (
                            <MinMaxCounter
                              min={user.min_slots}
                              max={user.max_slots}
                              setMin={value =>
                                dispatch({
                                  type: 'UPDATE_USER_REQS',
                                  id_user: user.id_user,
                                  payload: { min_slots: value },
                                })
                              }
                              disabled={!user.included}
                              setMax={value =>
                                dispatch({
                                  type: 'UPDATE_USER_REQS',
                                  id_user: user.id_user,
                                  payload: { max_slots: value },
                                })
                              }
                            />
                          )}
                          {state.req_type === UserRequirementType.DURATION && (
                            <MinMaxCounter
                              min={user.min_duration}
                              max={user.max_duration}
                              disabled={!user.included}
                              setMin={value =>
                                dispatch({
                                  type: 'UPDATE_USER_REQS',
                                  id_user: user.id_user,
                                  payload: { min_duration: value },
                                })
                              }
                              setMax={value =>
                                dispatch({
                                  type: 'UPDATE_USER_REQS',
                                  id_user: user.id_user,
                                  payload: { max_duration: value },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ),
      )}
    </div>
  );
}
