import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { User, UserRole } from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';

type UsersFilterDropdownProps = {
  users: Record<number, User>;
  roles: Record<number, UserRole>;
  selectedUsers: Set<number>;
  onSelectionChange: (selected: Set<number>) => void;
};

function UsersFilterDropdown({
  users,
  roles,
  selectedUsers,
  onSelectionChange,
}: UsersFilterDropdownProps) {
  const toggleSelection = (ids: number[], add: boolean) => {
    const newselectedUsers = new Set(selectedUsers);
    ids.forEach(id => {
      if (add) {
        newselectedUsers.add(id);
      } else {
        newselectedUsers.delete(id);
      }
    });
    onSelectionChange(newselectedUsers);
  };

  const toggleRoleSelection = (roleId: number, allUserIds: number[]) => {
    const isSelected = allUserIds.every(id => selectedUsers.has(id));
    toggleSelection(allUserIds, !isSelected);
  };

  const selectAll = () => toggleSelection(Object.keys(users).map(Number), true);
  const deselectAll = () =>
    toggleSelection(Object.keys(users).map(Number), false);

  const { t } = useTranslation();

  return (
    <Popover as="div" className="relative inline-block text-left">
      <div>
        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
          <span>{t('generic.users')}</span>
          {Object.values(users).filter(user => user.id_user_role).length ===
          selectedUsers.size ? (
            <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
              {t('generic.all')}
            </span>
          ) : null}
          <ChevronDownIcon
            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Popover.Button>
      </div>

      <Popover.Panel className="absolute right-0 z-30 mt-2 w-max max-h-80 overflow-auto origin-top-right rounded-md bg-white py-4 shadow-2xl ring-1 ring-black ring-opacity-5">
        <div className="space-y-4">
          <div className="flex justify-between mb-4 text-xs px-2">
            <button
              className="text-red-600 hover:underline mr-2"
              onClick={deselectAll}
            >
              {t('generic.deselectAll')}
            </button>
            <button
              className="text-blue-600 hover:underline"
              onClick={selectAll}
            >
              {t('generic.selectAll')}
            </button>
          </div>
          {Object.values(roles)
            .filter(role =>
              Object.values(users).some(user => user.id_user_role === role.id),
            )
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(role => {
              const usersInRole = Object.values(users).filter(
                user => user.id_user_role === role.id,
              );
              const allSelected = usersInRole.every(user =>
                selectedUsers.has(user.id),
              );

              return (
                <div key={role.id}>
                  <div className="flex items-center bg-gray-200 px-6 py-1">
                    <input
                      id={`role-${role.id}`}
                      type="checkbox"
                      checked={allSelected}
                      onChange={() =>
                        toggleRoleSelection(
                          role.id,
                          usersInRole.map(user => user.id),
                        )
                      }
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label
                      htmlFor={`role-${role.id}`}
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      {role.name}
                    </label>
                  </div>
                  <div className="pl-12">
                    {usersInRole
                      .sort((a, b) =>
                        `${a.firstname} ${a.lastname}`.localeCompare(
                          `${b.firstname} ${b.lastname}`,
                        ),
                      )
                      .map(user => (
                        <div
                          key={user.id}
                          className="flex items-center py-0.5 px-2"
                        >
                          <input
                            id={`user-${user.id}`}
                            type="checkbox"
                            checked={selectedUsers.has(user.id)}
                            onChange={() =>
                              toggleSelection(
                                [user.id],
                                !selectedUsers.has(user.id),
                              )
                            }
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          />
                          <label
                            htmlFor={`user-${user.id}`}
                            className="ml-2 text-sm text-gray-900"
                          >
                            {user.firstname} {user.lastname}
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              );
            })}
        </div>
      </Popover.Panel>
    </Popover>
  );
}

export default UsersFilterDropdown;
