"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isVirtualSlotNeedsMin = exports.AlgComponentType = void 0;
var AlgComponentType;
(function (AlgComponentType) {
    // Check that a user has enough free slots to fill all its requirements for a rule.
    // Global constraints
    AlgComponentType["VIRTUAL_SLOT_NEEDS_MIN"] = "virtSlNeedsMin";
    AlgComponentType["VIRTUAL_SLOT_NEEDS_MAX"] = "virtualSlotNeedsMax";
    AlgComponentType["SINGLE_GROUP_INCOMPATIBILITY_MIN"] = "singleGroupIncompatibilityMin";
    AlgComponentType["SINGLE_GROUP_INCOMPATIBILITY_MAX"] = "singleGroupIncompatibilityMax";
    AlgComponentType["CROSS_GROUP_INCOMPATIBILITY"] = "crossGroupIncompatibility";
    // User constraints
    AlgComponentType["C_1"] = "c1";
    AlgComponentType["C_CUSTOM_REST_PERIOD"] = "customRestPeriod";
    AlgComponentType["C_PERSONAL_BLOCKING"] = "personalBlocking";
    AlgComponentType["C_JUSTIFIED_BLOCKING"] = "justifiedBlocking";
    AlgComponentType["C_SPECIAL_EVENTS"] = "specialEvents";
    AlgComponentType["C_PREVIOUSLY_ASSIGNED_SHIFTS"] = "previouslyAssignedShifts";
    AlgComponentType["C_USER_SHIFT_REQUIREMENT_RULE_MAX_SLOTS"] = "userShiftRequirementRuleMaxSlots";
    AlgComponentType["C_USER_SHIFT_REQUIREMENT_RULE_MIN_SLOTS"] = "userShiftRequirementRuleMinSlots";
    AlgComponentType["C_USER_SHIFT_REQUIREMENT_RULE_MAX_DURATION"] = "userShiftRequirementRuleMaxDuration";
    AlgComponentType["C_USER_SHIFT_REQUIREMENT_RULE_MIN_DURATION"] = "userShiftRequirementRuleMinDuration";
    AlgComponentType["C_PENALTY_WORKER_MAX"] = "penaltyWorkerMax";
})(AlgComponentType || (exports.AlgComponentType = AlgComponentType = {}));
function isVirtualSlotNeedsMin(type, data) {
    return type === AlgComponentType.VIRTUAL_SLOT_NEEDS_MIN;
}
exports.isVirtualSlotNeedsMin = isVirtualSlotNeedsMin;
