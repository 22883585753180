import { useQueryClient } from '@tanstack/react-query';
import {
  CustomCounterIncrementSchema,
  useCreateSlotMutation,
} from '@youshift/shared/hooks/mutations';
import { Iteration, SlotLabel } from '@youshift/shared/types';
import { generateErrorStringFromError } from '@youshift/shared/utils';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import SlotDetailsBase from './SlotDetails';

export interface EditSlotProps {
  shiftLabels: SlotLabel[];
  iteration: Iteration;
  idSection: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function CreateSlot({
  shiftLabels,
  iteration,
  idSection,
  setOpen,
}: EditSlotProps) {
  const [selectedLabelId, setSelectedLabelId] = useState<number | null>(null);
  const [startTime, setStartTime] = useState<string>(
    new Date(iteration.start_day).toISOString()?.replace(/Z$/, ''),
  );
  const [endTime, setEndTime] = useState<string>(
    new Date(iteration.start_day).toISOString()?.replace(/Z$/, ''),
  );
  const [counterIncrementSchemas, setCounterIncrementSchemas] = useState<
    CustomCounterIncrementSchema[]
  >([]);
  const [restHours, setRestHours] = useState<number>(0);

  const idItr = iteration.id_itr;

  const duration = useMemo(() => {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    // Get the difference in milliseconds
    const differenceInMs = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to minutes
    const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));

    return differenceInMinutes;
  }, [endTime, startTime]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const createSlotMutation = useCreateSlotMutation(queryClient, {
    onSuccess: () => {
      setOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['section', String(idSection)],
      });
      toast.success(t('manager.sectionsConfig.createSlotSuccess'));
    },
    onError: () => {
      setOpen(false);
    },
  });

  const handleSubmit = () => {
    if (selectedLabelId !== null) {
      createSlotMutation.mutate({
        id_section: idSection,
        id_itr: idItr,
        id_slot_label: selectedLabelId,
        rest_period: restHours * 60,
        duration_minutes: duration,
        start: startTime.slice(0, -1),
        custom_counter_increments: counterIncrementSchemas,
      });
    }
  };

  const buttonDisabled = useMemo(
    () => selectedLabelId === null || startTime === '' || endTime === '',
    [selectedLabelId, startTime, endTime],
  );

  return (
    <SlotDetailsBase
      counterIncrementSchemas={counterIncrementSchemas}
      setCounterIncrementSchemas={setCounterIncrementSchemas}
      selectedLabelId={selectedLabelId}
      setSelectedLabelId={setSelectedLabelId}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      restHours={restHours}
      setRestHours={setRestHours}
      duration={duration}
      shiftLabels={shiftLabels}
      buttonDisabled={buttonDisabled}
      onSubmit={handleSubmit}
      submitButtonLabel={t('generic.create')}
      itrStart={iteration.start_day}
      itrEnd={iteration.end_day}
    />
  );
}

export default CreateSlot;
