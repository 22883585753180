import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import {
  addDays,
  addMonths,
  dateToString,
  getFirstDayOfWeek,
  localeNormalizer,
  mergeIterationData,
  subtractDays,
  subtractMonths,
} from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-native-big-calendar';
import { EventStatus } from '@youshift/shared/types';
import { UserLayoutResponse } from '@youshift/shared/hooks/queries';

import { useUserContext } from '../../layouts/UserLayout';
import i18n from '../../utils/i18n';
import {
  customEventRenderer,
  customWeeklyEventRenderer,
  generateCalendarEvents,
  generateShiftAssignments,
} from '../../utils/calendar';
import SectionLegend from '../../components/Calendars/SectionLegend';
import Wrapper from '../../components/Wrapper';
import {
  createBaseIdMapping,
  createLatestBaseObjectMapping,
} from '../Stats/utils';
import { YSButton } from '../../components/Buttons';

function UserDashboard() {
  const { user, events, userLayout } = useUserContext();

  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);

  // State for calendar view and navigation
  const [calendarView, setCalendarView] = useState<'month' | 'week'>('month');
  const [currentDate, setCurrentDate] = useState(new Date());

  // Collect all sections, slots, assignments, and labels from all iterations
  const {
    sections: allSections,
    section_slots: allSectionSlots,
    shift_assignments: allShiftAssignments,
    slot_labels: allSlotLabels,
  } = useMemo(
    () =>
      mergeIterationData(userLayout.itrs, [
        'sections',
        'section_slots',
        'shift_assignments',
        'slot_labels',
      ]),
    [userLayout],
  );

  const sectionsBaseIds = createBaseIdMapping(
    allSections,
    'id_section',
    'base_id_section',
  );
  const latestSections = createLatestBaseObjectMapping(
    allSections,
    'id_section',
    'base_id_section',
  );

  // Navigation functions
  const navigate = {
    next: () => {
      if (calendarView === 'week') {
        setCurrentDate(prev => addDays(prev, 7));
      } else {
        setCurrentDate(prev => addMonths(prev, 1));
      }
    },
    previous: () => {
      if (calendarView === 'week') {
        setCurrentDate(prev => subtractDays(prev, 7));
      } else {
        setCurrentDate(prev => subtractMonths(prev, 1));
      }
    },
  };

  // Generate calendar events
  const calendarShiftAssignments = useMemo(
    () =>
      generateShiftAssignments(
        allShiftAssignments,
        allSectionSlots,
        latestSections,
        allSlotLabels,
        sectionsBaseIds,
      ),
    [
      allSectionSlots,
      allShiftAssignments,
      allSlotLabels,
      latestSections,
      sectionsBaseIds,
    ],
  );

  const calendarEvents = useMemo(
    () =>
      generateCalendarEvents(
        events.filter(e => e.status === EventStatus.APPROVED),
      ),
    [events],
  );

  return (
    <Wrapper>
      <div className="flex flex-row justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900 mb-1">
            {t('user.dashboard.welcomeBack', { name: user.firstname })}
          </h1>
          <p className="text-lg mb-4">
            {t('user.dashboard.thisIsYourSchedule')}
          </p>
        </div>
        {user.group?.display_team_assignments && (
          <YSButton
            to="/user/team-assignments"
            classNames="flex flex-row gap-1 items-center h-min"
          >
            <EyeIcon className="w-4 h-4" />
            {t('user.dashboard.teamAssignments')}
          </YSButton>
        )}
      </div>
      <p className="text-xl font-semibold text-blue-600">
        {dateToString(currentDate, 'month-year')}
      </p>
      <div className="flex flex-row justify-between mb-4 flex-wrap">
        <SectionLegend sections={Object.values(latestSections)} />

        <div className="flex gap-2 mt-2">
          <select
            value={calendarView}
            onChange={e => setCalendarView(e.target.value as 'month' | 'week')}
            className="rounded-md border-gray-300 pl-3 pr-10 text-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
          >
            <option value="week">{t('calendars.weekView')}</option>
            <option value="month">{t('calendars.monthView')}</option>
          </select>

          <button
            onClick={navigate.previous}
            className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
            aria-label="previous"
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
          <button
            onClick={navigate.next}
            className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
            aria-label="next"
          >
            <ChevronRightIcon className="w-4 h-4" />
          </button>
        </div>
      </div>

      <Calendar
        locale={locale}
        events={[...calendarShiftAssignments, ...calendarEvents]}
        height={700}
        mode={calendarView}
        date={currentDate}
        weekStartsOn={getFirstDayOfWeek(locale) === 7 ? 0 : 1}
        renderEvent={
          calendarView === 'month'
            ? customEventRenderer
            : customWeeklyEventRenderer
        }
        showTime={calendarView === 'week'}
        ampm={false}
        hourRowHeight={25}
      />
    </Wrapper>
  );
}

export default UserDashboard;
