import { Combobox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAssignSlotMutation,
  useReplaceSlotMutation,
  useUnassignSlotMutation,
} from '@youshift/shared/hooks/mutations';
import {
  EventType,
  ShiftAssignment,
  UserPreferenceType,
} from '@youshift/shared/types';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ArrangedSectionSlot } from '../../pages/Manager/IterationConfig/Sections/types';
import EventSquare from '../Calendars/EventSquare';

type People = {
  id: number;
  name: string;
  preference: EventType | UserPreferenceType;
  points: number;
};

interface CustomComboboxProps {
  people: People[];
  needIdx?: number;
  slot: ArrangedSectionSlot;
  fadedOut: boolean;
  setAdditionalComboboxes?: Dispatch<SetStateAction<number>>;
  lastCombobox?: boolean;
  assignment?: ShiftAssignment;
  assigneeName?: string;
}

export default function CustomCombobox({
  people,
  slot,
  fadedOut,
  setAdditionalComboboxes,
  lastCombobox,
  assignment,
  assigneeName,
}: CustomComboboxProps) {
  const [selected, setSelected] = useState(assignment?.id_user || null);
  // console.log(slot, people, needIdx, selected);
  const [query, setQuery] = useState('');

  const { idItr } = useParams();

  const { t } = useTranslation();

  // filters unassignedPeople based on query
  const filteredData =
    query === ''
      ? people
      : people.filter(el =>
          el.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        );
  const queryClient = useQueryClient();

  const assignSlotMutation = useAssignSlotMutation(queryClient, {
    onSuccess: (data, variables) => {
      toast.success(t('manager.shiftAssignment.assignSuccess'));
      const assignedPerson = people.find(
        person => person.id === variables.id_user,
      )?.id;
      setAdditionalComboboxes && setAdditionalComboboxes(0);
      setSelected(assignedPerson || null);
      queryClient.invalidateQueries({ queryKey: ['shiftAssignments', idItr] });
    },
  });

  const unassignSlotMutation = useUnassignSlotMutation(queryClient, {
    onSuccess: () => {
      setSelected(null);
      toast.success(t('manager.shiftAssignment.unassignSuccess'));
      queryClient.invalidateQueries({ queryKey: ['shiftAssignments', idItr] });
    },
  });

  const replaceSlotMutation = useReplaceSlotMutation(queryClient, {
    onSuccess: (data, variables) => {
      toast.success(t('manager.shiftAssignment.replaceSuccess'));
      const newlyAssignedPerson = people.find(
        person => person.id === variables.new_user,
      )?.id;
      setSelected(newlyAssignedPerson || null);
      queryClient.invalidateQueries({ queryKey: ['shiftAssignments', idItr] });
    },
  });

  const handleChange = (idUser?: number | null) => {
    // x was clicked. unassigning
    if (idUser === undefined && selected) {
      unassignSlotMutation.mutate({
        id_itr: idItr, // Adjust this based on how you obtain id_itr
        id_section_slot: slot.id_section_slot!,
        id_user: selected,
      });
    }
    // replacing
    else if (idUser && selected && idUser !== selected) {
      replaceSlotMutation.mutate({
        id_itr: idItr, // Adjust this based on how you obtain id_itr
        id_section_slot: slot.id_section_slot!,
        new_user: idUser,
        current_user: selected,
      });
    }
    // assigning to empty slot
    else if (idUser) {
      assignSlotMutation.mutate({
        id_itr: idItr, // Adjust this based on how you obtain id_itr
        id_section_slot: slot.id_section_slot!,
        id_user: idUser,
      });
    }
  };

  return (
    <Combobox value={selected} onChange={handleChange}>
      <div className={`relative mt-1 ${fadedOut ? 'opacity-20' : ''}`}>
        <div className="flex flex-row">
          {(selected || lastCombobox) && (
            <button
              className="absolute -top-1.5 -right-0.5 z-10"
              onClick={() => {
                if (selected) {
                  handleChange();
                } else {
                  setAdditionalComboboxes?.(prevState => prevState - 1);
                }
              }}
            >
              <XMarkIcon className="h-3 w-3 text-gray-600" />
            </button>
          )}
          <div className="relative w-full z-0 cursor-default overflow-hidden rounded-lg mx-1 box-border px-1 py-0.5 border border-gray-400 bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 text-sm">
            {selected ? (
              <Combobox.Button
                as="p"
                className="cursor-pointer text-xs line-clamp-2"
              >
                {assigneeName}
              </Combobox.Button>
            ) : (
              <>
                <Combobox.Input
                  className="w-full border-none pl-0.5 py-0.5 leading-5 text-gray-900 focus:ring-0 text-xs whitespace-normal break-words"
                  displayValue={(el: People) => el?.name}
                  onChange={event => {
                    setQuery(event.target.value);
                  }}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-0.5">
                  <ChevronUpDownIcon
                    className="h-3 w-3 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </>
            )}
          </div>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="z-20 absolute mt-1 max-h-60 min-w-fit overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none text-xs">
            {filteredData.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredData.map(el => (
                <Combobox.Option
                  key={el.id}
                  className={({ selected, active }) =>
                    `grid grid-cols-[max-content_1fr] items-center relative gap-2 cursor-default select-none p-0.5 ${
                      active
                        ? 'bg-teal-100/50 text-gray-900'
                        : selected
                          ? 'bg-teal-500/50'
                          : 'text-gray-900'
                    }`
                  }
                  value={el.id}
                >
                  {({ selected, active }) => (
                    <>
                      {/* <div className="grid grid-cols-2 items-center"> */}
                      <span
                        className={`block truncate px-1 ${selected ? 'font-bold' : 'font-normal'}`}
                      >
                        {el.name}
                      </span>
                      <span className="justify-self-end">
                        <EventSquare
                          e={el.preference}
                          points={el.points}
                          justPoints
                          small
                        />
                      </span>
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
