import { CursorArrowRippleIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateUserReqRule } from '@youshift/shared/hooks/mutations';
import { RuleTypes, UserRequirementType } from '@youshift/shared/types';
import { getShadeMap } from '@youshift/shared/utils';
import { useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';
import ShiftLabelLegend from '../../../../components/Calendars/ShiftLabelLegend';
import { VirgueriaVersion } from '../../../../components/ManualAssignment/types';
import { Virgueria } from '../../../../components/ManualAssignment/Virgueria';
import VerticalProgressBar from '../../../../components/VerticalProgressBar';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';
import DefineReqs from './DefineReqs';
import { useSlotSelection } from './hooks/useSlotSelection';
import RuleType from './RuleType';
import { useUserReqReducer } from './useUserReqReducer';

export default function NewReqRule() {
  const { iteration, sectionsWithSlots, shiftLabels, itrUsers, roles, users } =
    useItrContext();
  const [ruleName, setRuleName] = useState('');
  const [ruleDescription, setRuleDescription] = useState('');
  const [selectedSection, setSelectedSection] = useState<number | null>(null);
  const [selectedLabel, setSelectedLabel] = useState<number | null>(null);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);
  const navigate = useNavigate();
  const children = [
    { name: t('manager.rulesConfig.ruleType'), key: 'type' },
    { name: t('manager.rulesConfig.selectSlots'), key: 'slots' },
    { name: t('manager.rulesConfig.defineReqs'), key: 'reqs' },
  ];
  const [childIndex, setChildIndex] = useState(0);

  const {
    selectedSlots,
    toggleSlot,
    onDayClick,
    onSectionClick,
    filteredShiftLabels,
    toggleLabel,
  } = useSlotSelection({
    sectionsWithSlots,
    initialSlots: new Set<number>(),
    shiftLabels,
  });

  const createRuleMutation = useCreateUserReqRule(queryClient, {
    onSuccess: () => {
      navigate('..', { relative: 'path' });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', String(iteration.id_itr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['userReqRules', String(iteration.id_itr)],
      });
    },
  });

  const { initialState, state, dispatch } = useUserReqReducer({
    itrUsers: Object.keys(itrUsers).map(Number),
    roles,
    users,
    userReqs: undefined,
    initialReqType: UserRequirementType.SLOTS,
  });

  const [ruleType, ruleTypeDispatch] = useReducer(
    (state: RuleTypes | null, action: RuleTypes) => {
      switch (action) {
        case RuleTypes.SECTION_USER_REQS:
          // For section user reqs, the default is that all users are included and then we set 0-0 or other min-max configurations
          dispatch({ type: 'INCLUDE_ALL' });
          return RuleTypes.SECTION_USER_REQS;
        case RuleTypes.SLOT_LABEL_USER_REQS:
          // For slot label user reqs, the default is that all users are unincluded/unregulated and we set bounds for specific users
          dispatch({ type: 'UNINCLUDE_ALL' });
          return RuleTypes.SLOT_LABEL_USER_REQS;
        case RuleTypes.CUSTOM_USER_REQS:
          // For custom user reqs, the default is that all users are unincluded/unregulated and we set bounds for specific users
          dispatch({ type: 'UNINCLUDE_ALL' });
          return RuleTypes.CUSTOM_USER_REQS;
        default:
          return state;
      }
    },
    null,
  );

  const ruleTypeIsIncomplete =
    (ruleType === RuleTypes.SECTION_USER_REQS && selectedSection === null) ||
    (ruleType === RuleTypes.SLOT_LABEL_USER_REQS && selectedLabel === null) ||
    (ruleType === RuleTypes.CUSTOM_USER_REQS && ruleName === '');

  const shouldNextButtonBeDisabled = useMemo(
    () =>
      (selectedSlots.size === 0 &&
        childIndex > 0 &&
        ruleType === RuleTypes.CUSTOM_USER_REQS) ||
      !ruleType ||
      ruleTypeIsIncomplete,
    [selectedSlots.size, childIndex, ruleType, ruleTypeIsIncomplete],
  );

  const createRule = () => {
    createRuleMutation.mutate({
      id_itr: iteration.id_itr,
      name: ruleName,
      description: ruleDescription,
      req_type: state.req_type,
      id_section_slots:
        ruleType === RuleTypes.CUSTOM_USER_REQS
          ? Array.from(selectedSlots)
          : undefined,
      id_section:
        ruleType === RuleTypes.SECTION_USER_REQS ? selectedSection : undefined,
      id_slot_label:
        ruleType === RuleTypes.SLOT_LABEL_USER_REQS ? selectedLabel : undefined,
      user_requirements: Object.values(state.roles).flatMap(role =>
        role.users
          .filter(user => user.included)
          .map(user => {
            if (state.req_type === UserRequirementType.DURATION) {
              return {
                id_user: user.id_user,
                min_duration: user.min_duration,
                max_duration: user.max_duration,
              };
            }
            return {
              id_user: user.id_user,
              min_slots: user.min_slots,
              max_slots: user.max_slots,
            };
          }),
      ),
    });
  };

  const returnChildComponent = useMemo(() => {
    switch (childIndex) {
      case 0:
        return (
          <RuleType
            ruleDescription={ruleDescription}
            ruleName={ruleName}
            ruleType={ruleType}
            sectionsWithSlots={sectionsWithSlots}
            selectedLabel={selectedLabel}
            selectedSection={selectedSection}
            setRuleDescription={setRuleDescription}
            setRuleName={setRuleName}
            setRuleType={ruleTypeDispatch}
            setSelectedLabel={setSelectedLabel}
            setSelectedSection={setSelectedSection}
            shiftLabels={shiftLabels}
          />
        );
      case 1:
        return (
          <div className="ml-3">
            <ShiftLabelLegend
              labels={filteredShiftLabels}
              onLabelClick={toggleLabel}
            />
            <Virgueria
              end={iteration.end_day}
              labels={shiftLabels}
              onDayClick={onDayClick}
              onSectionClick={onSectionClick}
              onSlotClick={toggleSlot}
              sectionsWithSlots={sectionsWithSlots}
              selectedSlots={selectedSlots}
              shadeMap={shadeMap}
              start={iteration.start_day}
              version={VirgueriaVersion.RulesCreating}
            />
          </div>
        );
      case 2:
        return (
          <DefineReqs
            state={state}
            dispatch={dispatch}
            numberOfSlotsSelected={selectedSlots.size}
          />
        );
      default:
        return null;
    }
  }, [
    childIndex,
    dispatch,
    filteredShiftLabels,
    iteration.end_day,
    iteration.start_day,
    onDayClick,
    onSectionClick,
    ruleDescription,
    ruleName,
    ruleType,
    sectionsWithSlots,
    selectedLabel,
    selectedSection,
    selectedSlots,
    shadeMap,
    shiftLabels,
    state,
    toggleLabel,
    toggleSlot,
  ]);

  return (
    <div className="py-6 h-full">
      <div className="flex flex-row h-full">
        <div className="self-start mt-12">
          <VerticalProgressBar
            steps={children.filter(
              child =>
                ruleType === RuleTypes.CUSTOM_USER_REQS ||
                child.key !== 'slots',
            )}
            childIndex={childIndex}
          />
        </div>
        <div className="w-full">
          <div className="p-8 flex flex-row justify-between items-center">
            <div>
              <h1 className="text-2xl font-semibold mb-2 text-gray-800">
                {children[childIndex].name}
              </h1>
              <p className="text-gray-500">
                {t('generic.stepXofY', {
                  current: childIndex + 1,
                  total: children.length,
                })}
              </p>
            </div>
            <div className="flex-shrink-0 mt-6">
              <div className="flex flex-row gap-2 justify-end">
                <YSButton
                  variant="ghost-primary"
                  onClick={() => navigate('..', { relative: 'path' })}
                >
                  {t('generic.cancel')}
                </YSButton>
                {
                  // if first step, don't show previous button
                  childIndex === 0 ? null : (
                    <YSButton
                      onClick={() =>
                        childIndex === 2 &&
                        ruleType !== RuleTypes.CUSTOM_USER_REQS
                          ? setChildIndex(childIndex - 2)
                          : setChildIndex(childIndex - 1)
                      }
                      variant="secondary"
                    >
                      {t('generic.back')}
                    </YSButton>
                  )
                }
                {childIndex === children.length - 1 ? (
                  <YSButton
                    onClick={createRule}
                    variant="primary"
                    disabled={shouldNextButtonBeDisabled}
                    loading={createRuleMutation.isPending}
                  >
                    {t('generic.create')}
                  </YSButton>
                ) : (
                  <YSButton
                    onClick={() =>
                      childIndex === 0 &&
                      ruleType !== RuleTypes.CUSTOM_USER_REQS
                        ? setChildIndex(childIndex + 2)
                        : setChildIndex(childIndex + 1)
                    }
                    variant="primary"
                    disabled={shouldNextButtonBeDisabled}
                  >
                    {t('generic.next')}
                  </YSButton>
                )}
              </div>
            </div>
          </div>
          {childIndex === 1 ? (
            <div className="flex flex-row gap-1 ml-8 -mt-4 mb-2 justify-center">
              <CursorArrowRippleIcon className="h-5 w-5 text-teal-600" />
              <p className="font-semibold">
                {t('manager.rulesConfig.clickPrompt')}
              </p>
            </div>
          ) : null}
          {returnChildComponent}
        </div>
      </div>
    </div>
  );
}
