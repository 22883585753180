import { Switch } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { request } from '@youshift/shared/api';
import { useToggleDisplayTeamAssignmentsMutation } from '@youshift/shared/hooks/mutations';
import { StripeSubscription } from '@youshift/shared/hooks/queries';
import { StripeSubscriptionStatus } from '@youshift/shared/types';
import {
  expiredSubscriptionStatuses,
  getSubscriptionStatusColor,
} from '@youshift/shared/utils';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Wrapper from '../components/Wrapper';
import { useManagerContext } from '../layouts/ManagerLayout';

export default function Settings() {
  const { t } = useTranslation();
  const { subscriptions, user } = useManagerContext();

  const { display_team_assignments } = user.group || {};
  const [displayTeamAssignments, setDisplayTeamAssignments] = useState(
    Boolean(display_team_assignments),
  );

  const queryClient = useQueryClient();

  // Get the most recent subscription
  const currentSubscriptions =
    subscriptions?.length > 0 ? [subscriptions[0]] : [];

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  const getAmount = (subscription: StripeSubscription) => {
    const item = subscription.items.data[0];
    if (!item.price.unit_amount) return 'Custom pricing';
    return `$${((item.price.unit_amount / 100) * item.quantity).toFixed(2)}/${subscription.plan.interval}`;
  };

  const handleManageSubscription = async () => {
    try {
      const { session_url } = await request<{ session_url: string }>({
        url: 'manager/group/payments/create-portal-session',
        method: 'GET',
      });
      window.location.href = session_url;
    } catch (error) {
      console.error('Failed to create portal session:', error);
      toast.error(t('generic.error'));
    }
  };

  const toggleDisplayTeamAssignments = useToggleDisplayTeamAssignmentsMutation(
    queryClient,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        toast.success(t('manager.settings.settingsUpdateSuccess'));
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        setDisplayTeamAssignments(!displayTeamAssignments);
      },
    },
  );

  return (
    <Wrapper mt="mt-16">
      <h1 className="text-2xl font-bold mb-8">{t('generic.settings')}</h1>

      <div className="space-y-8">
        {/* Subscription Section */}
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3 pb-8 border-b border-gray-200">
          <div>
            <h2 className="text-base/7 font-semibold text-gray-900">
              {t('stripe.subscription')}
            </h2>
            <p className="mt-1 text-sm/6 text-gray-600">
              {t('stripe.manageSubscriptionLong')}
            </p>
          </div>

          <div className="md:col-span-2">
            <div className="flex flex-col gap-4">
              {currentSubscriptions.length > 0 ? (
                currentSubscriptions.map(currentSubscription => (
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-2 py-4 border-b border-b-gray-200">
                    <div>
                      <h3 className="text-sm font-medium text-gray-600">
                        {t('stripe.status')}
                      </h3>
                      <div className="mt-1">
                        <span
                          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${getSubscriptionStatusColor(currentSubscription.status)}`}
                        >
                          {t(`stripe.${currentSubscription.status}`)}
                        </span>
                      </div>
                    </div>

                    <div>
                      <h3 className="text-sm font-medium text-gray-600">
                        {t('stripe.plan')}
                      </h3>
                      <p className="mt-1 text-sm text-gray-900">
                        {getAmount(currentSubscription)}
                      </p>
                    </div>

                    {currentSubscription.default_payment_method?.card && (
                      <div>
                        <h3 className="text-sm font-medium text-gray-600">
                          {t('stripe.paymentMethod')}
                        </h3>
                        <p className="mt-1 text-sm text-gray-900">
                          {
                            currentSubscription.default_payment_method.card
                              .brand
                          }{' '}
                          {t('stripe.endingIn')}{' '}
                          {
                            currentSubscription.default_payment_method.card
                              .last4
                          }
                        </p>
                      </div>
                    )}

                    <div>
                      <h3 className="text-sm font-medium text-gray-600">
                        {currentSubscription.status ===
                        StripeSubscriptionStatus.TRIALING
                          ? t('stripe.freeTrialEnds')
                          : expiredSubscriptionStatuses.includes(
                                currentSubscription.status,
                              )
                            ? t('stripe.subscriptionExpiredAt')
                            : t('stripe.nextBillingDate')}
                      </h3>
                      <p className="mt-1 text-sm text-gray-900">
                        {formatDate(currentSubscription.current_period_end)}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-600">
                  {t('stripe.contactToStart')}
                </p>
              )}

              {currentSubscriptions.length > 0 && (
                <div className="col-span-2">
                  <button
                    type="button"
                    className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600"
                    onClick={handleManageSubscription}
                  >
                    {currentSubscriptions[0].status ===
                    StripeSubscriptionStatus.PAUSED
                      ? t('stripe.reactivate')
                      : t('stripe.manageSubscription')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Team Assignments Switch Section */}
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3 pb-8">
          <div>
            <h2 className="text-base/7 font-semibold text-gray-900">
              {t('manager.settings.displayTeamAssignments')}
            </h2>
            <p className="mt-1 text-sm/6 text-gray-600">
              {t('manager.settings.displayTeamAssignmentsDescription')}
            </p>
          </div>

          <div className="md:col-span-2">
            <Switch
              checked={displayTeamAssignments}
              onChange={() => {
                setDisplayTeamAssignments(!displayTeamAssignments);
                toggleDisplayTeamAssignments.mutate();
              }}
              className={`${
                displayTeamAssignments ? 'bg-teal-500' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2`}
            >
              <span
                aria-hidden="true"
                className={`${
                  displayTeamAssignments ? 'translate-x-5' : 'translate-x-0'
                } inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
