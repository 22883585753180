import React, { useMemo } from 'react';
import { MinusIcon } from '@heroicons/react/24/solid'; // or wherever you import it from
import {
  SectionSlot,
  ShiftAssignment,
  UserReqRule,
  UserRequirement,
  UserRequirementType,
} from '@youshift/shared/types';
import { getDifferenceInHours } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';
import { HandThumbUpIcon } from '@heroicons/react/24/outline';

interface RequirementsTableProps {
  userReqsForUser: Record<number, UserRequirement | null>;
  allUserReqs: Record<number, UserReqRule>;
  assignmentsForUser: ShiftAssignment[];
  sectionSlots?: Record<number, SectionSlot>;
}

export function UserRequirementsTable({
  userReqsForUser,
  allUserReqs,
  assignmentsForUser = [],
  sectionSlots = {},
}: RequirementsTableProps) {
  const { t } = useTranslation();

  const rules = useMemo(() => {
    const results: Array<{
      ruleId: number;
      ruleName: string;
      assignedUnits: number;
      isDurationRule: boolean;
      minReq: number | string;
      maxReq: number | string;
      // 0 means it's within range, -n means it's n below, n it's n above
      result: number;
    }> = [];

    // Go through each rule in your master `allUserReqs`.
    // If you only want to show rules that the user actually has, you can also
    // iterate over `Object.keys(userReqsForUser)`.

    for (const ruleIdStr of Object.keys(userReqsForUser)) {
      const ruleId = parseInt(ruleIdStr, 10);
      const ruleObj = allUserReqs[ruleId];
      const userRequirement = userReqsForUser[ruleId]; // might be undefined

      const ruleName = ruleObj.rule.name;

      // Find all assignments for this user that belong to the section slots of this rule.
      const relevantAssignments = assignmentsForUser.filter(assignment =>
        ruleObj.section_slots.includes(assignment.id_section_slot),
      );

      const isDurationRule =
        userRequirement?.req_type === UserRequirementType.DURATION;

      const assignedUnits = isDurationRule
        ? relevantAssignments.reduce((sum, assignment) => {
            const slot = sectionSlots[assignment.id_section_slot];
            return slot
              ? sum + getDifferenceInHours(slot.start, slot.end)
              : sum;
          }, 0)
        : relevantAssignments.length;

      if (!userRequirement) {
        results.push({
          ruleId,
          ruleName,
          isDurationRule,
          assignedUnits,
          minReq: '∞',
          maxReq: '∞',
          result: 0,
        });
      } else {
        const minReq =
          (isDurationRule
            ? userRequirement.min_duration
            : userRequirement.min_slots) || 0;

        const maxReq =
          (isDurationRule
            ? userRequirement.max_duration
            : userRequirement.max_slots) || 0;

        const result =
          assignedUnits >= minReq && assignedUnits <= maxReq
            ? 0
            : assignedUnits > maxReq
              ? assignedUnits - maxReq // yields a positive number if above
              : assignedUnits - minReq; // yields a negative number if below

        results.push({
          ruleId,
          ruleName,
          isDurationRule,
          assignedUnits,
          minReq,
          maxReq,
          result,
        });
      }
    }

    return results;
  }, [userReqsForUser, allUserReqs, assignmentsForUser, sectionSlots]);

  // If you want to hide the table entirely if no deficits exist, you can filter:
  // const deficitRows = deficitRules.filter(row => row.missing > 0 || row.missing === "∞");
  // if (!deficitRows.length) return null;

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden my-2">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
              <th className="px-3 py-1.5 text-left">
                {t('generic.name')}{' '}
                {/* e.g., the rule name; or you can put user name if you have multiple users */}
              </th>
              <th className="px-3 py-1.5 text-center">
                {t('manager.assignmentErrors.has')}
              </th>
              <th className="px-3 py-1.5 text-center">{t('generic.min')}</th>
              <th className="px-3 py-1.5 text-center">{t('generic.max')}</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rules.map(
              ({
                ruleId,
                ruleName,
                assignedUnits,
                minReq,
                maxReq,
                result,
                isDurationRule,
              }) => (
                <tr key={ruleId} className="hover:bg-gray-50">
                  <td className="flex items-center gap-0.5 px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                    <span className="relative">
                      {result === 0 ? (
                        <HandThumbUpIcon className="w-4 h-4 text-green-600" />
                      ) : (
                        <p className="text-red-600">
                          {result <= 0 ? result : `+${result}`}
                        </p>
                      )}
                    </span>
                    {ruleName}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                    {assignedUnits}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                    {isDurationRule ? `${minReq} h` : minReq}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                    {isDurationRule ? `${maxReq} h` : maxReq}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
