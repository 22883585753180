/* eslint-disable max-len */
import { Listbox, Transition } from '@headlessui/react';
import {
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  ItrPreferenceSchema,
  UserPrefSchema,
  useDeleteUserPrefMutation,
  useEditItrPrefsMutation,
  useEditUserPrefMutation,
} from '@youshift/shared/hooks/mutations';
import {
  UserPrefsResponse,
  userPrefsQuery,
} from '@youshift/shared/hooks/queries';
import {
  EventStatus,
  UserPreferenceType,
  UserRequirementType,
} from '@youshift/shared/types';
import {
  addMonths,
  calculatePointsForItr,
  classNames,
  dateToString,
  getCustomDateRange,
  localeNormalizer,
  parseIterationDates,
  subtractMonths,
} from '@youshift/shared/utils';
import { Fragment, useMemo, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-native-big-calendar';
import {
  LoaderFunctionArgs,
  Navigate,
  useLoaderData,
  useParams,
} from 'react-router-dom';

import EventSquare from '../../components/Calendars/EventSquare';
import SectionLegend from '../../components/Calendars/SectionLegend';
import SectionSlotChip from '../../components/Calendars/SectionSlotChip';
import Alert from '../../components/FormFeedback/Alert';
import Information from '../../components/Information';
import LabelIconComponent from '../../components/LabelIconComponent';
import Modal from '../../components/Modal';
import PreferencesLegend from '../../components/Calendars/PreferencesLegend';
import SelfAssignShift from './SelfAssignShift';
import { YSButton } from '../../components/Buttons';
import {
  CalendarEvents,
  CalendarShiftAssignments,
  CalendarUserPreference,
  customEventRenderer,
  generateCalendarEvents,
  generateEventsByDate,
  generateShiftAssignments,
  generateTimedPrefSlots,
  isCalendarShiftAssignments,
  isCalendarUserPreference,
} from '../../utils/calendar';
import { requireApproved, requireLoggedIn } from '../../utils/checks';
import { backendToLocalDate } from '../../utils/helpers';
import i18n from '../../utils/i18n';
import { useUserContext } from '../../layouts/UserLayout';

export const preferencesLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<UserPrefsResponse | null> => {
    const user = await requireLoggedIn(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const results = preLoadQuery(queryClient, userPrefsQuery(params.idItr));
    return results;
  };

// edits the state that keeps track of all userPrefs
type UserPreferencesAction =
  | {
      type: 'EDIT_PREF';
      id_pref_slot: number;
      preference?: UserPreferenceType;
      justification?: string | null;
      points?: number;
    }
  | { type: 'DELETE_PREF'; id_pref_slot: number };

// edits a specific, selected userPref
type UserPreferenceAction =
  | { type: 'SELECT_EVENT'; event: CalendarUserPreference }
  | { type: 'CLEAR_EVENT' }
  | { type: 'UPDATE_JUSTIFICATION'; justification: string | null }
  | { type: 'UPDATE_POINTS'; points: number }
  | { type: 'UPDATE_PREFERENCE'; preference: UserPreferenceType };

export default function Preferences() {
  // Router and context hooks
  const { idItr } = useParams();
  const { events } = useUserContext();
  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);
  const queryClient = useQueryClient();

  // Query data
  const loaderData = useLoaderData() as UserPrefsResponse;
  const {
    data: {
      chain,
      itr,
      itr_user,
      pref_slots,
      section_slots,
      sections,
      slot_labels,
      user_prefs,
      shift_assignments,
      user_shift_reqs,
      associated_shift_req_rules,
      rules_section_slots,
    },
  } = useQuery({ ...userPrefsQuery(idItr || ''), initialData: loaderData });

  // State
  const [calendarMonthStart, setCalendarMonthStart] = useState(
    new Date(...backendToLocalDate(itr.start_day)),
  );
  const [infoOpen, setInfoOpen] = useState(false);
  const [prefModalOpen, setPrefModalOpen] = useState(false);
  const [savedChanges, setSavedChanges] = useState(true);
  const [selfAssignShiftOpen, setSelfAssignShiftOpen] = useState(false);
  const [selfAssignedShiftToDelete, setSelfAssignedShiftToDelete] =
    useState<CalendarShiftAssignments | null>(null);

  // Excluded slots memo
  const excludedSlots = useMemo(() => {
    const excludedShiftReqs = Object.entries(user_shift_reqs)
      .filter(
        ([_, userShiftReq]) =>
          (userShiftReq.req_type === UserRequirementType.DURATION &&
            userShiftReq.min_duration === 0 &&
            userShiftReq.max_duration === 0) ||
          (userShiftReq.req_type === UserRequirementType.SLOTS &&
            userShiftReq.min_slots === 0 &&
            userShiftReq.max_slots === 0),
      )
      .map(([idRule, _]) => Number(idRule));
    return excludedShiftReqs.flatMap(idRule => rules_section_slots[idRule]);
  }, [rules_section_slots, user_shift_reqs]);

  // Calendar-related memos
  const eventsByDate = useMemo(() => generateEventsByDate(events), [events]);

  const filteredPrefSlots = useMemo(() => {
    return excludedSlots
      ? Object.values(pref_slots).filter(prefSlot => {
          return !excludedSlots.includes(prefSlot.id_pref_slot);
        })
      : pref_slots;
  }, [excludedSlots, pref_slots]);

  const calendarTimedPrefSlots = useMemo(
    () =>
      generateTimedPrefSlots(
        filteredPrefSlots,
        section_slots,
        slot_labels,
        user_prefs,
        eventsByDate,
        shift_assignments,
      ),
    [
      eventsByDate,
      filteredPrefSlots,
      section_slots,
      shift_assignments,
      slot_labels,
      user_prefs,
    ],
  );

  const calendarShiftAssignments = useMemo(
    () =>
      generateShiftAssignments(
        shift_assignments,
        section_slots,
        sections,
        slot_labels,
      ),
    [section_slots, sections, shift_assignments, slot_labels],
  );

  const calendarEvents = useMemo(
    () =>
      generateCalendarEvents(
        events.filter(event => event.status === EventStatus.APPROVED),
      ),
    [events],
  );

  // keeps track of the userPrefs stored in the database (not every pref slot has a user pref)
  // we keep track of it instead of invalidating the query to avoid api calls
  const userPrefsReducer = (
    state: Record<number, UserPrefSchema>,
    action: UserPreferencesAction,
  ): Record<number, UserPrefSchema> => {
    switch (action.type) {
      case 'EDIT_PREF': {
        const { id_pref_slot, preference, justification, points } = action;
        const newState = {
          ...state,
          [id_pref_slot]: {
            ...state[id_pref_slot],
            id_pref_slot,
            ...(preference !== undefined && { preference }),
            ...(justification !== undefined && { justification }),
            ...(points !== undefined && { points }),
          },
        };
        // if (!state[id_pref_slot]) {
        //   return state;
        // }
        return newState;
      }

      case 'DELETE_PREF': {
        const { [action.id_pref_slot]: _, ...remainingPrefs } = state;
        return remainingPrefs;
      }

      default:
        return state;
    }
  };

  function itrPrefReducer(
    state: ItrPreferenceSchema,
    action: {
      fieldName: keyof ItrPreferenceSchema;
      value: ItrPreferenceSchema[keyof ItrPreferenceSchema];
    },
  ): ItrPreferenceSchema {
    setSavedChanges(false);
    return {
      ...state,
      [action.fieldName]: action.value,
    };
  }

  // keeps track of which event is clicked in the calendar and its changes to be saved
  const selectedEventReducer = (
    state: CalendarUserPreference | null,
    action: UserPreferenceAction,
  ): CalendarUserPreference | null => {
    if (action.type === 'SELECT_EVENT') {
      return action.event;
    }
    if (!state) {
      return null;
    }
    switch (action.type) {
      case 'CLEAR_EVENT':
        return null;
      case 'UPDATE_JUSTIFICATION':
        return {
          ...state,
          justification: action.justification,
        };
      case 'UPDATE_POINTS':
        return {
          ...state,
          points: action.points,
        };
      case 'UPDATE_PREFERENCE':
        return {
          ...state,
          preference: action.preference,
          justification:
            action.preference === UserPreferenceType.JUSTIFIED_BLOCKING
              ? ''
              : null,
          points: 0,
        };
      default:
        return state;
    }
  };

  // Preference tracking and validation
  const [userPrefs, userPrefsDispatch] = useReducer(
    userPrefsReducer,
    Object.values(user_prefs).reduce<Record<number, UserPrefSchema>>(
      (acc, pref) => ({
        ...acc,
        [pref.id_pref_slot]: {
          id_pref_slot: pref.id_pref_slot,
          preference: pref.preference,
          justification: pref.justification ?? null,
          points: pref.points,
        },
      }),
      {},
    ),
  );

  const [itrPref, itrPrefDispatch] = useReducer(itrPrefReducer, {
    P_101: itr_user.P_101,
    P_77: itr_user.P_77,
    comment: itr_user.comment || '',
    spread: itr_user.spread,
    volunteer: itr_user.volunteer,
  });

  const [selectedEvent, dispatchSelectedEvent] = useReducer(
    selectedEventReducer,
    null,
  );
  const [selectedEventInitialState, setSelectedEventInitialState] =
    useState<CalendarUserPreference | null>(null);

  // Validation memos
  const toJustify = useMemo(() => {
    const eventsToJustify: { id: number; justification: string | null }[] = [];
    Object.values(userPrefs).forEach(pref => {
      if (pref.preference === UserPreferenceType.JUSTIFIED_BLOCKING) {
        eventsToJustify.push({
          id: pref.id_pref_slot,
          justification: pref.justification ?? null,
        });
      }
    });
    return eventsToJustify;
  }, [userPrefs]);

  const used = useMemo(() => {
    let sumPoints = 0;
    let bCount = 0;
    let uCount = 0;

    for (const userPref of Object.values(userPrefs)) {
      if (userPref.preference === UserPreferenceType.POINTS) {
        sumPoints += Math.abs(userPref.points);
      } else if (userPref.preference === UserPreferenceType.PERSONAL_BLOCKING) {
        bCount++;
      } else if (
        userPref.preference === UserPreferenceType.JUSTIFIED_BLOCKING
      ) {
        uCount++;
      }
    }

    return [
      {
        key: t('generic.points'),
        value: sumPoints,
        color: 'fill-green-500',
        max: calculatePointsForItr(itr_user),
      },
      {
        key: t('generic.wildcards'),
        value: bCount,
        color: 'fill-yellow-500',
        max: itr_user.base_jokers,
      },
      {
        key: t('generic.justifiedBlock'),
        value: uCount,
        color: 'fill-cyan-300',
      },
    ];
  }, [itr_user, t, userPrefs]);

  const limitsAreRespected =
    used[0].value <= used[0].max! && used[1].value <= used[1].max!;
  const eventsAreJustified = toJustify.every(
    element => element.justification && element.justification.trim() !== '',
  );
  const goingOverLimits = useMemo(
    () =>
      selectedEvent
        ? itr_user.base_points -
            used[0].value -
            selectedEvent.points +
            (selectedEventInitialState?.points || 0) <
            0 ||
          (itr_user.base_jokers - used[1].value ===
            0 +
              Number(
                selectedEventInitialState?.preference ===
                  UserPreferenceType.PERSONAL_BLOCKING,
              ) &&
            selectedEvent.preference === UserPreferenceType.PERSONAL_BLOCKING)
        : false,
    [itr_user, selectedEvent, selectedEventInitialState, used],
  );

  // Mutations
  const editItrPrefsMutation = useEditItrPrefsMutation(queryClient, {
    onSuccess: () => {
      toast.success(t('generic.changesSaved'));
      setSavedChanges(true);
    },
  });

  const editUserPrefMutation = useEditUserPrefMutation(queryClient, {
    onSuccess: (data, variables) => {
      const {
        user_pref: { id_pref_slot, preference, justification, points },
      } = variables;
      userPrefsDispatch({
        type: 'EDIT_PREF',
        id_pref_slot,
        preference,
        justification: justification || '',
        points,
      });
      queryClient.invalidateQueries({ queryKey: ['userPrefs', idItr] });
      setPrefModalOpen(false);
      toast.success(t('generic.success'));
    },
  });

  const deleteUserPrefMutation = useDeleteUserPrefMutation(queryClient, {
    onSuccess: (data, variables) => {
      const { id_pref_slot } = variables;
      userPrefsDispatch({
        type: 'DELETE_PREF',
        id_pref_slot,
      });
      queryClient.invalidateQueries({ queryKey: ['userPrefs', idItr] });
      toast.success(t('generic.success'));
      setPrefModalOpen(false);
    },
  });

  // Calendar navigation
  const goToNextMonth = () => {
    setCalendarMonthStart(prev => addMonths(prev, 1));
  };

  const goToPreviousMonth = () => {
    setCalendarMonthStart(prev => subtractMonths(prev, 1));
  };

  const preferencesInformation = {
    title: t('user.preferences.title'),
    text: (
      <p className="text-sm text-gray-500">
        {t('user.preferences.description')}
        <ul className="my-2">
          <li className="mb-2">
            <span className="text-green-400 font-semibold">
              {t('user.preferences.points')}
            </span>
            {t('user.preferences.usePoints')}
          </li>

          <li className="mb-2">
            <span className="text-yellow-400 font-semibold">
              {t('user.preferences.personalBlock')}
            </span>
            {t('user.preferences.usePersonalBlock')}
          </li>
          <li className="mb-2">
            <span className="text-cyan-400 font-semibold">
              {t('user.preferences.justifiedBlock')}
            </span>
            {t('user.preferences.useJustifiedBlock')}
          </li>
        </ul>
        {/* <p className="mt-6">
          <span className="font-semibold">
            {t('user.preferences.important')}
          </span>
          {t('user.preferences.note')}
        </p> */}
      </p>
    ),
  };

  const daysOfItr = getCustomDateRange(
    itr.start_day,
    itr.end_day,
    locale,
    'table',
  );

  return (
    <>
      {itr.locked ? <Navigate to="/user/iterations" replace /> : null}
      {eventsAreJustified ? null : (
        <Alert
          success={false}
          text={t('user.preferences.eventsNotJustified')}
        />
      )}
      {limitsAreRespected ? null : (
        <Alert
          success={false}
          text={t('user.preferences.limitsNotRespected')}
        />
      )}
      <Information
        open={infoOpen}
        setOpen={setInfoOpen}
        title={preferencesInformation.title}
        text={preferencesInformation.text}
      />
      {prefModalOpen && selectedEvent ? (
        <Modal
          isOpen={prefModalOpen}
          onClose={() => {
            dispatchSelectedEvent({ type: 'CLEAR_EVENT' });
            setSelectedEventInitialState(null);
            setPrefModalOpen(false);
          }}
          editButtons
          handleSave={() => {
            if (goingOverLimits) {
              return;
            }
            editUserPrefMutation.mutate({
              id_itr: idItr,
              user_pref: {
                id_pref_slot: selectedEvent.id_pref_slot,
                preference: selectedEvent.preference,
                justification: selectedEvent.justification || '',
                points: selectedEvent.points,
              },
            });
          }}
          handleDelete={
            selectedEvent &&
            selectedEventInitialState &&
            selectedEventInitialState.preference
              ? () =>
                  deleteUserPrefMutation.mutate({
                    id_itr: idItr,
                    id_pref_slot: selectedEvent.id_pref_slot,
                  })
              : undefined
          }
          disableSave={
            selectedEvent.preference ===
              UserPreferenceType.JUSTIFIED_BLOCKING &&
            !selectedEvent.justification
          }
        >
          <h3 className="font-semibold">
            {`${t('user.preferences.addPreference')}:`}
          </h3>
          <div className="flex flex-row gap-1 items-center">
            <LabelIconComponent
              icon={selectedEvent.icon}
              className="h-5 w-5 text-teal-600"
            />
            <p>{selectedEvent.title}</p>
            <span>-</span>
            <CalendarIcon className="h-5 w-5 text-blue-600" />
            <p>{dateToString(selectedEvent.start, 'long')}</p>
          </div>
          {goingOverLimits ? (
            <Alert
              success={false}
              text={t('user.preferences.limitsNotRespected')}
            />
          ) : null}
          <div className="flex sm:flex-col min-[1244px]:flex-row flex-row justify-start mt-4 mb-4 items-center">
            <Listbox
              value={selectedEvent.preference}
              onChange={(e: UserPreferenceType) =>
                dispatchSelectedEvent({
                  type: 'UPDATE_PREFERENCE',
                  preference: e,
                })
              }
            >
              {({ open }) => (
                <div className="relative mr-2">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                    <span className="flex items-center">
                      <span className="ml-3 block">
                        {selectedEvent.preference
                          ? t(
                              `user.preferences.${selectedEvent.preference}long`,
                            )
                          : t('user.preferences.enterPreference')}
                      </span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {Object.values(UserPreferenceType).map(
                        (preferenceOption, index) => (
                          <Listbox.Option
                            key={index}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-blue-600 text-white'
                                  : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                              )
                            }
                            value={preferenceOption}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'ml-3 block',
                                    )}
                                  >
                                    {t(
                                      `user.preferences.${preferenceOption}long`,
                                    )}
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-blue-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ),
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
            {selectedEvent.preference ? (
              <EventSquare
                e={selectedEvent.preference}
                points={selectedEvent.points}
                dispatch={dispatchSelectedEvent}
                maxPoints={itr_user.base_points - used[0].value}
                small
                noMargin
              />
            ) : null}
          </div>
          {selectedEvent.preference ===
          UserPreferenceType.JUSTIFIED_BLOCKING ? (
            <div className="py-4 -mt-3">
              <h3 className="font-medium mb-2">
                {t('manager.editPreference.justifyLabel')}
              </h3>
              <input
                type="text"
                value={selectedEvent.justification || ''}
                onChange={e =>
                  dispatchSelectedEvent({
                    type: 'UPDATE_JUSTIFICATION',
                    justification: e.target.value,
                  })
                }
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
          ) : null}
          <p>{t('user.preferences.prefSlotDetails')}</p>
          {selectedEvent.associatedSectionSlots.map(sectionSlotId => {
            const slot = section_slots[sectionSlotId];
            return (
              <SectionSlotChip
                sectionName={sections[slot.id_section].name}
                start={slot.start}
                end={slot.end}
              />
            );
          })}
        </Modal>
      ) : null}
      {selfAssignShiftOpen && (
        <SelfAssignShift
          days={daysOfItr}
          open={selfAssignShiftOpen}
          sections={sections}
          sectionSlots={section_slots}
          setOpen={setSelfAssignShiftOpen}
          toDelete={selfAssignedShiftToDelete}
        />
      )}
      <div className="space-y-12 sm:space-y-16 md:mt-16">
        <div className="lg:flex lg:h-full lg:flex-col px-4 sm:px-6 lg:px-8">
          <header className="flex items-center justify-between lg:flex-none">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">
              {parseIterationDates(itr.start_day, itr.end_day, itr.itr_type)}
            </h1>
          </header>
          <div className="flex md:flex-row flex-col my-6 justify-between">
            {used.map(badge => (
              <span className="inline-flex items-center gap-x-1.5 rounded-md px-4 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                <svg
                  className={`h-1.5 w-1.5 ${badge.color}`}
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                >
                  <circle cx={3} cy={3} r={3} />
                </svg>
                {`${badge.key} ${t('user.preferences.used')}: ${badge.value} ${badge?.max || badge?.max === 0 ? `/ ${badge.max}` : ''}`}
              </span>
            ))}
            <button type="button" onClick={() => setInfoOpen(true)}>
              <InformationCircleIcon className="text-blue-600 h-8" />
            </button>
          </div>

          {itr.message && (
            <div>
              <div className="flex flex-row items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-blue-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                  />
                </svg>
                <label
                  htmlFor="comment"
                  className="block text-md font-medium leading-6 text-gray-900"
                >
                  {t('user.iterations.managerMessage')}
                </label>
              </div>
              <p className="mt-2 text-sm font-normal text-gray-700 p-3 border border-gray-300 rounded-md">
                {itr.message}
              </p>
            </div>
          )}
          <section className="mb-2 mt-2">
            <label className="relative">
              <input
                type="checkbox"
                className="peer/showLabel absolute scale-0"
              />
              <span className="flex peer-checked/showLabel:hidden">
                <ChevronDownIcon className="absolute right-2 w-10 p-2 text-white cursor-pointer" />
              </span>
              <span className="hidden peer-checked/showLabel:flex">
                <ChevronUpIcon className="absolute right-2 w-10 p-2 text-white cursor-pointer" />
              </span>
              <span className="block max-h-10 max-w-100 overflow-hidden rounded-lg py-0 text-white font-bold  shadow-md transition-all duration-300 peer-checked/showLabel:max-h-fit">
                <h3 className="flex h-10 cursor-pointer items-center px-4 bg-blue-600">
                  {t('user.preferences.myConfig')}
                </h3>
                <div className="shadow ring-1 px-4 ring-black ring-opacity-5 flex flex-auto flex-col my-3 h-100">
                  <div className="grid grid-cols-5 gap-px border-b border-gray-300 bg-gray-200 text-xs leading-6 font-semibold text-gray-700 lg:flex-none">
                    <div className="bg-white p-2">
                      {t('user.preferences.shift')}
                    </div>
                    <div className="bg-white p-2">
                      {t('user.preferences.minShift')}
                    </div>
                    <div className="bg-white p-2">
                      {t('user.preferences.maxShift')}
                    </div>
                    <div className="bg-white p-2">
                      {t('user.preferences.minDuration')}
                    </div>
                    <div className="bg-white p-2">
                      {t('user.preferences.maxDuration')}
                    </div>
                  </div>

                  {Object.values(user_shift_reqs).map(req => (
                    <div className="grid grid-cols-5 bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                      <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                        {associated_shift_req_rules[req.id_rule].name}
                      </div>
                      <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                        {req.min_slots}
                      </div>
                      <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                        {req.max_slots}
                      </div>
                      <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                        {req.min_duration}
                      </div>
                      <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                        {req.max_duration}
                      </div>
                    </div>
                  ))}
                </div>
                <p className="text-gray-600 font-normal text-sm my-2 ml-4">
                  <span className="font-bold">
                    {t('user.preferences.pointsBreakdownBold')}
                  </span>
                  {t('user.preferences.pointsBreakdownExplanation', {
                    points: itr_user.base_points,
                    notRespected: itr_user.not_respected_from_prev,
                    accumulated: itr_user.points_saved_from_prev,
                    rewards: itr_user.reassignment_rewards_counter || 0,
                    total: used[0].max,
                  })}
                </p>
                <p className="font-normal text-gray-600 text-xs ml-4 my-2">
                  {t('user.preferences.pointsToAccumulate', {
                    points: chain?.max_saved_points_allowed,
                  })}
                </p>
              </span>
            </label>
          </section>
          <div className="mb-2 flex flex-row justify-between">
            <PreferencesLegend />
            <SectionLegend sections={Object.values(sections)} />
          </div>
          <div className="flex flex-row justify-between items-center mb-2">
            <div className="flex flex-row gap-1">
              <button
                onClick={goToPreviousMonth}
                className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
                aria-label="previous-week"
              >
                <ChevronLeftIcon className="w-4 h-4" />
              </button>
              <button
                aria-label="next-week"
                onClick={goToNextMonth}
                className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
              >
                <ChevronRightIcon className="w-4 h-4" />
              </button>
              <p className="text-md font-semibold text-blue-600">
                {dateToString(calendarMonthStart, 'month-year')}
              </p>
            </div>
            <YSButton
              type="button"
              variant="outline-primary"
              classNames="flex flex-row items-center gap-0.5"
              onClick={() => {
                setSelfAssignedShiftToDelete(null);
                setSelfAssignShiftOpen(true);
              }}
            >
              <PlusIcon className="w-4 h-4" />
              {t('user.preferences.selfAssignShift')}
            </YSButton>
          </div>
          <Calendar
            events={[
              ...calendarTimedPrefSlots,
              ...calendarShiftAssignments,
              ...calendarEvents,
            ]}
            mode="month"
            height={600}
            date={calendarMonthStart}
            renderEvent={customEventRenderer}
            onPressEvent={(event: CalendarEvents) => {
              if (isCalendarUserPreference(event)) {
                dispatchSelectedEvent({
                  type: 'SELECT_EVENT',
                  event,
                });
                setSelectedEventInitialState(event);
                setPrefModalOpen(true);
              } else if (isCalendarShiftAssignments(event)) {
                setSelfAssignedShiftToDelete(event);
                setSelfAssignShiftOpen(true);
              }
            }}
            maxVisibleEventCount={10}
            locale={locale}
          />
          <p className="mb-2 mt-4 font-semibold text-blue-600">
            {t('user.preferences.otherConfig')}
          </p>
          <div className="mb-4 rounded-md flex flex-col gap-3">
            <div className="flex flex-row gap-1 bg-gray-50 shadow-md p-3 rounded-md w-fit">
              <p>{t('user.preferences.maxDobletes')}</p>
              <input
                id="dobletes"
                name="dobletes"
                type="number"
                value={itrPref.P_101}
                onChange={e =>
                  itrPrefDispatch({ fieldName: 'P_101', value: e.target.value })
                }
                autoComplete="off"
                min="0"
                className="border border-solid border-blue-600 ml-3 w-11 h-7 pl-2 rounded-md block p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="flex flex-row gap-1 items-center bg-gray-50 shadow-md p-3 rounded-md w-fit">
              <p>{t('user.preferences.sameDay')}</p>
              <input
                id="same-day"
                name="same-day"
                type="checkbox"
                checked={itrPref.P_77}
                onChange={e =>
                  itrPrefDispatch({
                    fieldName: 'P_77',
                    value: e.target.checked,
                  })
                }
                className="m-2 h-5 w-5 rounded-md border border-solid border-blue-600"
              />
            </div>
            <div className="flex flex-row gap-1 items-center bg-gray-50 shadow-md p-3 rounded-md w-fit">
              <p>{t('user.preferences.separation')}</p>
              <input
                id="spread"
                name="spread"
                type="checkbox"
                checked={itrPref.spread}
                onChange={e =>
                  itrPrefDispatch({
                    fieldName: 'spread',
                    value: e.target.checked,
                  })
                }
                className="m-2 h-5 w-5 rounded-md border border-solid border-blue-600"
              />
            </div>
            <div className="flex flex-row gap-1 items-center bg-gray-50 shadow-md p-3 rounded-md w-fit">
              <p>{t('user.preferences.volunteer')}</p>
              <input
                id="volunteer"
                name="volunteer"
                type="checkbox"
                checked={Boolean(itrPref.volunteer)}
                onChange={e =>
                  itrPrefDispatch({
                    fieldName: 'volunteer',
                    value: e.target.checked ? 1 : 0,
                  })
                }
                className="m-2 h-5 w-5 rounded-md border border-solid border-blue-600"
              />
            </div>
          </div>

          <p className="mb-2 font-semibold text-blue-600">
            {t('user.preferences.comments')}
          </p>
          <div>
            <div className="mt-2">
              <textarea
                rows={4}
                name="comment"
                id="comment"
                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:py-1.5 sm:text-sm sm:leading-6"
                placeholder={t('generic.otherComments')}
                value={itrPref.comment}
                onChange={e =>
                  itrPrefDispatch({
                    fieldName: 'comment',
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="mt-8 mb-4 mx-auto">
            {savedChanges ? (
              <p className=" p-3 rounded-xl">{t('generic.savedChanges')}</p>
            ) : (
              <button
                type="button"
                className="ml-6 rounded-md cursor-pointer disabled:cursor-not-allowed disabled:opacity-50  bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                disabled={!eventsAreJustified || !limitsAreRespected}
                onClick={() =>
                  editItrPrefsMutation.mutate({
                    id_itr: idItr,
                    itr_preference: itrPref,
                  })
                }
              >
                {t('user.preferences.savePreferences')}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
