import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowPathRoundedSquareIcon,
  Bars3Icon,
  BuildingOffice2Icon,
  CalendarIcon,
  ChartBarIcon,
  Cog8ToothIcon,
  GlobeEuropeAfricaIcon,
  HomeIcon,
  UserIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { request } from '@youshift/shared/api';
import {
  allChainsQuery,
  ChainsResponse,
  countersQuery,
  CustomCountersReturn,
  groupEventsQuery,
  loadUserQuery,
  personnelQuery,
  PersonnelReturn,
  StripeSubscription,
  subscriptionsQuery,
  SubscriptionsResponse,
} from '@youshift/shared/hooks/queries';
import { SpecialEvent, User, UserRole } from '@youshift/shared/types';
import { createContext, Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigation,
} from 'react-router-dom';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  SubscriptionStatusBanner,
  isAllowedToUsePlatform,
  userSubscriptionStatus,
} from '@youshift/shared/utils';

import { requireManager } from '../utils/checks';

interface NavigationItem {
  id: string;
  name: string;
  href: string;
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
}

export type ManagerContextType = {
  user: User;
  events: SpecialEvent[];
  users: Record<number, User>;
  roles: Record<number, UserRole>;
  counters: CustomCountersReturn;
  allChains: ChainsResponse;
  subscriptions: StripeSubscription[];
};

export const ManagerContext = createContext<ManagerContextType | undefined>(
  undefined,
);

export function useManagerContext() {
  const context = useContext(ManagerContext);
  if (!context) {
    throw new Error('useManagerContext must be used within an ManagerProvider');
  }
  return context;
}

type ManagerLoader = {
  user: User;
  events: SpecialEvent[];
  personnel: PersonnelReturn;
  counters: CustomCountersReturn;
  allChains: ChainsResponse;
  subscriptions: SubscriptionsResponse;
};

export const managerLoader =
  (queryClient: QueryClient) => async (): Promise<ManagerLoader> => {
    const user = await requireManager(queryClient);
    const events = await preLoadQuery(queryClient, groupEventsQuery());
    const personnel = await preLoadQuery(queryClient, personnelQuery());
    const counters = await preLoadQuery(queryClient, countersQuery());
    const allChains = await preLoadQuery(queryClient, allChainsQuery());
    const subscriptions = await preLoadQuery(queryClient, subscriptionsQuery());
    return { user, events, personnel, counters, allChains, subscriptions };
  };

export const useManagerLoader = (): ManagerContextType => {
  const initialData = useLoaderData() as ManagerLoader;
  const { data: user } = useQuery({
    ...loadUserQuery(),
    initialData: initialData.user,
  });
  // TEMPORARY
  if (new Date(user.token_last_refresh) < new Date('01/30/2025')) {
    localStorage.removeItem('token');
    window.location.href = '/login';
  }
  const { data: events } = useQuery({
    ...groupEventsQuery(),
    initialData: initialData.events,
  });
  const { data: personnel } = useQuery({
    ...personnelQuery(),
    initialData: initialData.personnel,
  });
  const { data: counters } = useQuery({
    ...countersQuery(),
    initialData: initialData.counters,
  });
  const { data: allChains } = useQuery({
    ...allChainsQuery(),
    initialData: initialData.allChains,
  });
  const { data: subscriptions } = useQuery({
    ...subscriptionsQuery(),
    initialData: initialData.subscriptions,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    select: (data: SubscriptionsResponse) => data.data,
  });
  const { users, roles } = personnel;
  return { user, events, users, roles, counters, allChains, subscriptions };
};

export default function ManagerLayout(): JSX.Element {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const contextValue = useManagerLoader();
  const { user, subscriptions } = contextValue;
  const user_is_admin = user?.is_manager >= 80;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  // Get subscription status
  const subscriptionStatus = userSubscriptionStatus(user.group!);
  const canUsePlatform = isAllowedToUsePlatform(subscriptionStatus.status);
  const navigation: NavigationItem[] = canUsePlatform
    ? [
        {
          id: 'dashboard',
          name: t('manager.navigation.home'),
          href: 'dashboard',
          icon: HomeIcon,
        },
        {
          id: 'team',
          name: t('manager.navigation.team'),
          href: 'team',
          icon: UsersIcon,
        },
        {
          id: 'chains',
          name: t('manager.navigation.iterations'),
          href: 'chains',
          icon: CalendarIcon,
        },
        {
          id: 'exchange',
          name: t('generic.shiftExchange.title'),
          href: 'exchange',
          icon: ArrowPathRoundedSquareIcon,
        },
        {
          id: 'event',
          name: t('eventCenter.eventCenter'),
          href: 'event',
          icon: GlobeEuropeAfricaIcon,
        },
        {
          id: 'stats',
          name: t('generic.stats'),
          href: 'stats',
          icon: ChartBarIcon,
        },
        // {
        //   id: 'help',
        //   name: t('manager.navigation.help'),
        //   href: 'help',
        //   icon: QuestionMarkCircleIcon,
        // },
        {
          id: 'settings',
          name: t('generic.settings'),
          href: 'settings',
          icon: Cog8ToothIcon,
        },
        {
          id: 'user',
          name: t('manager.navigation.userProfile'),
          href: '/user',
          icon: UserIcon,
        },
      ]
    : [
        {
          id: 'dashboard',
          name: t('manager.navigation.home'),
          href: 'dashboard',
          icon: HomeIcon,
        },
        {
          id: 'team',
          name: t('manager.navigation.team'),
          href: 'team',
          icon: UsersIcon,
        },
        // {
        //   id: 'help',
        //   name: t('manager.navigation.help'),
        //   href: 'help',
        //   icon: QuestionMarkCircleIcon,
        // },
        {
          id: 'settings',
          name: t('generic.settings'),
          href: 'settings',
          icon: Cog8ToothIcon,
        },
      ];

  const navigationState = useNavigation();

  const exitImpersonateManagerMutation = useMutation({
    mutationFn: () =>
      request({ url: '/admin/exit_impersonate_manager', method: 'post' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  const paymentMethod =
    subscriptions?.length > 0
      ? subscriptions[0].default_payment_method?.card
      : undefined;

  // Only show banner for specific conditions: user hasn't closed it, it's not active, and trial is not ending in more than 10 days
  const shouldShowBanner =
    showBanner &&
    subscriptionStatus.status !== SubscriptionStatusBanner.ACTIVE &&
    !(
      subscriptionStatus.status === SubscriptionStatusBanner.TRIAL_ENDING &&
      ((subscriptionStatus.daysLeft !== undefined &&
        subscriptionStatus.daysLeft > 10) ||
        paymentMethod)
    );

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blue-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <Link to="/" className="flex flex-shrink-0 items-center px-4">
                    <img
                      src="https://youshift-docs.s3.eu-west-3.amazonaws.com/youshift_bubbles.png"
                      alt="YouShift"
                      className="h-12 w-12"
                    />
                  </Link>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map(item => (
                      <NavLink
                        to={item.href}
                        key={item.name}
                        className="[&.active]:bg-blue-800 text-white hover:bg-blue-600 hover:bg-opacity-75 group flex items-center rounded-md px-2 py-2 text-base font-medium"
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 flex-shrink-0 text-blue-300"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
                <BuildingOffice2Icon className="text-white h-9" />
                <p className="text-center mb-11 font-semibold text-white text-lg">
                  {user?.org?.name}
                </p>
                <div className="flex flex-shrink-0 border-t border-blue-800 p-4">
                  <NavLink to="profile" className="group block flex-shrink-0">
                    <div className="flex items-center">
                      <div className="relative">
                        <div className="h-10 w-10 rounded-full bg-gradient-to-r from-blue-400 to-blue-300 flex items-center justify-center">
                          <span className="text-white font-medium text-lg">
                            {`${user?.firstname?.[0]}${user?.lastname?.[0]}`}
                          </span>
                        </div>
                        <div className="absolute bottom-0 right-0 h-3 w-3 rounded-full bg-green-400 border-2 border-white"></div>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white mb-0.5 text-nowrap">{`${user?.firstname} ${user?.lastname}`}</p>
                        <p className="text-xs text-blue-200 group-hover:text-white opacity-75">
                          {t('manager.navigation.seeProfile')}
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-24 lg:flex-col group hover:w-64 transition-all duration-300 ease-in-out z-50 p-4">
        <div className="flex flex-1 flex-col bg-gradient-to-b from-blue-700 to-blue-400 rounded-xl shadow-xl">
          <div className="flex flex-shrink-0 items-center w-20 px-4 my-3">
            <Link to="/" className="flex items-center justify-start w-full">
              <img
                className="h-8 w-auto"
                src="https://youshift-docs.s3.eu-west-3.amazonaws.com/youshift_bubbles.png"
                alt="YouShift"
              />
            </Link>
          </div>
          <div className="flex min-h-0 flex-1 flex-col">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {navigation.map(item => {
                  const isPending =
                    navigationState.state === 'loading' &&
                    navigationState.location.pathname === '/manager/stats';
                  return (
                    <NavLink
                      to={item.href}
                      key={item.name}
                      className="[&.active]:bg-blue-800 [&.pending]:bg-blue-700 text-white hover:bg-blue-600 hover:bg-opacity-75 group flex items-center rounded-md px-2 py-2 text-base font-medium transition-all duration-300 ease-in-out"
                    >
                      <item.icon
                        className="mr-4 h-6 w-6 flex-shrink-0 text-blue-300"
                        aria-hidden="true"
                      />
                      <span className="hidden group-hover:inline text-pretty">
                        {item.name}
                      </span>
                      {isPending && item.href === 'stats' ? (
                        <svg
                          className="animate-spin ml-4 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      ) : null}
                    </NavLink>
                  );
                })}
                {user_is_admin ? (
                  <NavLink
                    to="/admin"
                    onClick={() => {
                      exitImpersonateManagerMutation.mutate();
                    }}
                    className="[&.active]:bg-blue-800 text-white hover:bg-blue-600 hover:bg-opacity-75 group flex items-center rounded-md px-2 py-2 text-base font-medium transition-all duration-300 ease-in-out"
                  >
                    <WrenchScrewdriverIcon
                      className="mr-4 h-6 w-6 flex-shrink-0 text-blue-300"
                      aria-hidden="true"
                    />
                    <span className="hidden group-hover:inline">
                      Portal Admin
                    </span>
                  </NavLink>
                ) : null}
              </nav>
            </div>
            <div className="hidden group-hover:flex flex-col items-center">
              <BuildingOffice2Icon className="text-white h-9" />
              <p className="text-center mb-11 font-semibold text-white text-lg text-nowrap">
                {user?.org?.name}
              </p>
            </div>
            <div className="flex flex-shrink-0 border-t border-white/20 p-2 py-4">
              <NavLink
                to="profile"
                className="group block w-full flex-shrink-0"
              >
                <div className="flex items-center">
                  <div className="relative">
                    <div className="h-10 w-10 rounded-full bg-gradient-to-r from-blue-400 to-blue-300 flex items-center justify-center">
                      <span className="text-white font-medium text-lg">
                        {`${user?.firstname?.[0]}${user?.lastname?.[0]}`}
                      </span>
                    </div>
                    <div className="absolute bottom-0 right-0 h-3 w-3 rounded-full bg-green-400 border-2 border-white"></div>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white mb-0.5 text-nowrap hidden group-hover:block ">{`${user?.firstname} ${user?.lastname}`}</p>
                    <p className="text-xs text-blue-200 group-hover:text-white opacity-75 hidden group-hover:block ">
                      {t('manager.navigation.seeProfile')}
                    </p>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col lg:pl-24">
        <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          {/* removed this class from the div below to allow for more content max-w-7xl */}
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <ManagerContext.Provider value={contextValue}>
              <Outlet />
            </ManagerContext.Provider>
          </div>
        </main>
      </div>

      {shouldShowBanner && (
        <div className="pointer-events-none fixed inset-x-0 top-0 sm:flex sm:justify-center sm:px-6 sm:pt-5 lg:px-8">
          <div
            className={`pointer-events-auto flex items-center justify-between gap-12 px-4 sm:rounded-xl py-3 ${
              subscriptionStatus.status === SubscriptionStatusBanner.EXPIRED
                ? 'bg-red-600'
                : subscriptionStatus.status ===
                    SubscriptionStatusBanner.GRACE_PERIOD
                  ? 'bg-amber-500'
                  : 'bg-blue-600'
            }`}
          >
            <p className="text-base leading-6 text-white">
              {subscriptionStatus.status ===
              SubscriptionStatusBanner.EXPIRED ? (
                <span className="font-semibold">
                  {t('stripe.expiredMessage')}
                </span>
              ) : subscriptionStatus.status ===
                SubscriptionStatusBanner.GRACE_PERIOD ? (
                <span className="font-semibold">
                  {t('stripe.graceMessage', {
                    daysLeft: subscriptionStatus.daysLeft!,
                  })}
                </span>
              ) : (
                <span className="font-semibold">
                  {t('stripe.trialMessage', {
                    daysLeft: subscriptionStatus.daysLeft!,
                  })}
                </span>
              )}
              <Link
                to="settings"
                className="ml-6 font-semibold text-white hover:text-gray-100 underline"
              >
                {subscriptionStatus.status === SubscriptionStatusBanner.EXPIRED
                  ? t('generic.reactivate')
                  : subscriptionStatus.status ===
                      SubscriptionStatusBanner.GRACE_PERIOD
                    ? t('generic.renewNow')
                    : t('generic.upgrade')}
                <span aria-hidden="true">&rarr;</span>
              </Link>
            </p>
            <button
              type="button"
              className="-m-1.5 flex-none p-1.5"
              onClick={() => setShowBanner(false)}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
