import {
  AssignmentCheckErrors,
  AssignmentCheckErrorType,
} from './assignment_checks/types';

export enum AssignmentCheckErrorStatus {
  // Error: the rule is being violated, and it cannot be fixed by the algorithm
  // This is due to a direct conflict or a surplus in the number of assigned workers
  VIOLATION = 'violation',
  // Warning: the rule is being violated, but it is due to a lack of assignments
  // Thus, the algorithm can fix it by assigning more workers
  DEFICIT = 'deficit',
  // Default: the rule is not being violated, but it is not being met either
  DEFAULT = 'default',
}

export const AssignmentCheckToStatusMap: Record<
  AssignmentCheckErrorType,
  AssignmentCheckErrorStatus
> = {
  [AssignmentCheckErrorType.USER_REQ_SLOTS_SURPLUS]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.USER_REQ_SLOTS_DEFICIT]:
    AssignmentCheckErrorStatus.DEFICIT,
  [AssignmentCheckErrorType.USER_REQ_DURATION_SURPLUS]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.USER_REQ_DURATION_DEFICIT]:
    AssignmentCheckErrorStatus.DEFICIT,
  [AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_SURPLUS]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_DEFICIT]:
    AssignmentCheckErrorStatus.DEFICIT,
  [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_OVERLAPPING_ASSIGNMENT]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.CROSS_GROUP_INCOMPATIBILITY]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_SURPLUS]:
    AssignmentCheckErrorStatus.VIOLATION,
  [AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_DEFICIT]:
    AssignmentCheckErrorStatus.DEFICIT,
  [AssignmentCheckErrorType.DEFAULT]: AssignmentCheckErrorStatus.DEFAULT,
};

export enum ErrorDisplaySeverity {
  // Display warnings (Yellow)
  WARNINGS = 'warnings',
  // Display errors (Red)
  ERRORS = 'errors',
  // Display no errors (Green)
  NO_ERRORS = 'no_errors',
}

export const determineErrorDisplaySeverity = (
  errors: AssignmentCheckErrors,
) => {
  // Collect all error types from the nested structure
  const allErrors = new Set<AssignmentCheckErrorType>();

  // Check user requirement rules
  Object.values(errors.user_req_rules).forEach(ruleErrors => {
    Object.keys(ruleErrors).forEach(errorType => {
      allErrors.add(errorType as AssignmentCheckErrorType);
    });
  });

  // Check single group incompatibilities
  Object.values(errors.single_group_incompatibilities).forEach(incompErrors => {
    Object.keys(incompErrors).forEach(errorType => {
      allErrors.add(errorType as AssignmentCheckErrorType);
    });
  });

  // Check cross group incompatibilities
  if (Object.keys(errors.cross_group_incompatibilities).length > 0) {
    allErrors.add(AssignmentCheckErrorType.CROSS_GROUP_INCOMPATIBILITY);
  }

  // Check user assignments
  Object.values(errors.user_assignments).forEach(userErrors => {
    Object.values(userErrors).forEach(slotErrors => {
      Object.keys(slotErrors).forEach(errorType => {
        allErrors.add(errorType as AssignmentCheckErrorType);
      });
    });
  });

  // Check virtual slots
  Object.values(errors.virtual_slots).forEach(slotErrors => {
    Object.keys(slotErrors).forEach(errorType => {
      allErrors.add(errorType as AssignmentCheckErrorType);
    });
  });

  // Check if any error in the list matches the checksThatAreErrors array
  const hasErrors = Array.from(allErrors).some(
    errorType =>
      AssignmentCheckToStatusMap[errorType as AssignmentCheckErrorType] ===
      AssignmentCheckErrorStatus.VIOLATION,
  );

  const hasDeficits = Array.from(allErrors).some(
    errorType =>
      AssignmentCheckToStatusMap[errorType as AssignmentCheckErrorType] ===
      AssignmentCheckErrorStatus.DEFICIT,
  );

  if (hasErrors) {
    return ErrorDisplaySeverity.ERRORS;
  }

  // If there are deficits but no errors, return WARNINGS
  if (hasDeficits) {
    return ErrorDisplaySeverity.WARNINGS;
  }

  // If no errors or warnings exist, return NO_ERRORS
  return ErrorDisplaySeverity.NO_ERRORS;
};

export const getSingleUserErrors = (
  errors: AssignmentCheckErrors,
  userId: number,
) => {
  // Collect all error types from the nested structure
  const allErrors = new Set<AssignmentCheckErrorType>();
  Object.values(errors.user_req_rules).forEach(ruleErrors => {
    Object.entries(ruleErrors).forEach(([errorType, errors]) => {
      if (errors[Number(userId)]) {
        allErrors.add(errorType as AssignmentCheckErrorType);
      }
    });
  });
  Object.entries(errors.user_assignments).forEach(
    ([errorUserId, userErrors]) => {
      if (Number(errorUserId) === Number(userId)) {
        Object.entries(userErrors).forEach(([slotId, slotErrors]) => {
          Object.keys(slotErrors).forEach(errorType => {
            allErrors.add(errorType as AssignmentCheckErrorType);
          });
        });
      }
    },
  );

  return Array.from(allErrors);
};

export const getSingleUserDisplaySeverity = (
  allErrors: AssignmentCheckErrorType[],
) => {
  const errorTypes = Array.from(allErrors).map(
    errorType =>
      AssignmentCheckToStatusMap[errorType as AssignmentCheckErrorType],
  );
  if (errorTypes.includes(AssignmentCheckErrorStatus.VIOLATION))
    return AssignmentCheckErrorStatus.VIOLATION;
  if (errorTypes.includes(AssignmentCheckErrorStatus.DEFICIT))
    return AssignmentCheckErrorStatus.DEFICIT;
  return null;
};
