import { Combobox, Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import {
  MinusCircleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  useSelfAssignSlotMutation,
  useSelfUnassignSlotMutation,
} from '@youshift/shared/hooks/mutations';
import type { Section, SectionSlot, User } from '@youshift/shared/types';
import {
  dateToString,
  areDatesOnTheSameDay,
  classNames,
} from '@youshift/shared/utils';
import { CSSProperties, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import toast from 'react-hot-toast';

import { YSButton } from '../../components/Buttons';
import SectionSlotChip from '../../components/Calendars/SectionSlotChip';
import { CalendarShiftAssignments } from '../../utils/calendar';

interface SelfAssignShiftProps {
  days: Date[];
  open: boolean;
  sections: Record<number, Section>;
  sectionSlots: Record<number, SectionSlot>;
  setOpen: (open: boolean) => void;
  toDelete: CalendarShiftAssignments | null;
}

export default function SelfAssignShift({
  days,
  open,
  sections,
  sectionSlots,
  setOpen,
  toDelete,
}: SelfAssignShiftProps) {
  const [selectedDay, setSelectedDay] = useState<Date | null>(
    toDelete?.start ?? null,
  );
  const [selectedSlot, setSelectedSlot] = useState<number | null>(
    toDelete?.id_section_slot ?? null,
  );

  const { idItr } = useParams();

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const assignSlotMutation = useSelfAssignSlotMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userPrefs', idItr] });
      setOpen(false);
      toast.success(t('manager.shiftAssignment.shiftAssigned'));
    },
  });

  const unassignSlotMutation = useSelfUnassignSlotMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userPrefs', idItr] });
      setOpen(false);
      toast.success(t('manager.shiftAssignment.shiftUnassigned'));
    },
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-center">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${selectedSlot ? 'bg-red-100' : 'bg-blue-100'} sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    {selectedSlot ? (
                      <MinusCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <PlusCircleIcon
                        className="h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {selectedSlot
                        ? t('manager.shiftAssignment.eliminateShift')
                        : t('manager.shiftAssignment.assignShift')}
                    </Dialog.Title>
                  </div>
                </div>
                {/* Day */}
                <div className="my-5 flex flex-row items-start gap-6">
                  <h2 className="font-semibold">
                    {t('manager.shiftAssignment.selectDay')}
                  </h2>
                  <div className="items-center">
                    <Listbox
                      value={selectedDay}
                      onChange={setSelectedDay}
                      disabled={toDelete !== null}
                    >
                      <div className="relative">
                        <Listbox.Button
                          className={classNames(
                            selectedDay ? 'bg-gray-200' : 'bg-white',
                            'relative cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm',
                          )}
                        >
                          <span className="block truncate">
                            {selectedDay
                              ? dateToString(selectedDay, 'weekday')
                              : '-'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {days.map(day => (
                              <Listbox.Option
                                key={dateToString(day, 'dd/mm/yyyy')}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`
                                }
                                value={day}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                    >
                                      {dateToString(day, 'weekday')}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
                {/* Slot */}
                <div className="my-5 flex flex-row items-start gap-6">
                  <h2 className="font-semibold">
                    {t('manager.shiftAssignment.selectSectionSlot')}
                  </h2>
                  <div className="items-center">
                    <Listbox
                      value={selectedSlot}
                      onChange={setSelectedSlot}
                      disabled={toDelete !== null}
                    >
                      <div className="relative">
                        <Listbox.Button
                          className={classNames(
                            selectedSlot ? 'bg-gray-200' : 'bg-white',
                            'relative cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm',
                          )}
                        >
                          <span className="block truncate">
                            {selectedSlot ? (
                              <SectionSlotChip
                                sectionName={
                                  sections[
                                    sectionSlots[selectedSlot]?.id_section
                                  ]?.name
                                }
                                start={sectionSlots[selectedSlot]?.start}
                                end={sectionSlots[selectedSlot]?.end}
                                small
                              />
                            ) : (
                              '-'
                            )}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="mt-1 max-h-x60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {selectedDay &&
                              Object.values(sectionSlots)
                                .filter(slotOption =>
                                  areDatesOnTheSameDay(
                                    slotOption.start,
                                    selectedDay,
                                  ),
                                )
                                .map(slot => (
                                  <Listbox.Option
                                    key={slot.id_section_slot}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`
                                    }
                                    value={slot.id_section_slot}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                        >
                                          <SectionSlotChip
                                            sectionName={
                                              sections[slot.id_section]?.name
                                            }
                                            start={slot.start}
                                            end={slot.end}
                                            small
                                          />
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 flex gap-4">
                  {toDelete ? (
                    <YSButton
                      type="button"
                      variant="delete"
                      onClick={() => {
                        unassignSlotMutation.mutate({
                          id_itr: Number(idItr),
                          id_section_slot: selectedSlot,
                        });
                      }}
                    >
                      {t('generic.eliminate')}
                    </YSButton>
                  ) : (
                    <YSButton
                      type="button"
                      onClick={() => {
                        assignSlotMutation.mutate({
                          id_itr: Number(idItr),
                          id_section_slot: selectedSlot,
                        });
                      }}
                      disabled={!selectedSlot}
                    >
                      {t('manager.shiftAssignment.assign')}
                    </YSButton>
                  )}
                  <YSButton
                    type="button"
                    variant="ghost-primary"
                    onClick={() => setOpen(false)}
                  >
                    {t('generic.cancel')}
                  </YSButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
