import { Menu, Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { UserStatsReturn } from '@youshift/shared/hooks/queries';
import { CustomCounter, Rule } from '@youshift/shared/types';
import {
  classNames,
  getWeekdayInitials,
  parseIterationDates,
  returnColor,
} from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LabelIconComponent from '../../../components/LabelIconComponent';
import UserStatsChart from '../../../components/Stats/UserStatsChart';
import Wrapper from '../../../components/Wrapper';
import {
  buildUserStats,
  calculateUserPercentages,
  createBaseIdMapping,
  createLatestBaseObjectMapping,
  createSectionSlotToRulesMapping,
  mergeUserIterationsData,
  toggleItrSelection,
  UserStats,
} from '../utils';

interface SingleUserStatsProps {
  chainStats: UserStatsReturn['chains'];
  counters: Record<number, CustomCounter>;
}

interface StatBoxProps {
  title: string;
  value: string;
  icon?: React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
}

export function StatBox({
  title,
  value,
  icon,
  backgroundColor,
  textColor,
}: StatBoxProps) {
  return (
    <div className="flex flex-col p-3 rounded-2xl bg-gray-50 shadow-sm">
      <dt
        className={`flex items-center justify-center gap-2 text-md font-semibold leading-6 rounded-xl py-2 px-4 text-center ${backgroundColor ? '' : 'bg-gray-100'}`}
        style={
          backgroundColor
            ? {
                backgroundColor,
                color: textColor,
              }
            : undefined
        }
      >
        {icon}
        {title}
      </dt>
      <dd className="mt-4 text-xl tracking-tight text-gray-900 text-center">
        {value}
      </dd>
    </div>
  );
}

export default function SingleUserStats({
  chainStats,
  counters,
}: SingleUserStatsProps) {
  const { t } = useTranslation();
  const [selectedChain, setSelectedChain] = useState(
    Number(Object.keys(chainStats)[0]),
  );

  const { chain_name, itrs } = chainStats[selectedChain] || {};
  const [selectedItrs, setSelectedItrs] = useState(
    new Set(Object.keys(itrs).map(Number)),
  );

  const {
    section_slots: allSectionSlots,
    user_req_rules: allUserReqRules,
    sections: allSections,
    slot_labels: allSlotLabels,
    shift_assignments: allShiftAssignments,
  } = useMemo(
    () =>
      mergeUserIterationsData(
        itrs,
        [
          'section_slots',
          'user_req_rules',
          'sections',
          'slot_labels',
          'shift_assignments',
        ],
        selectedItrs,
      ),
    [itrs, selectedItrs],
  );

  const allRules = Object.values(allUserReqRules).reduce<Record<number, Rule>>(
    (acc, { rule }) => {
      acc[rule.id_rule] = rule;
      return acc;
    },
    {},
  );

  const sectionSlotToRules = useMemo(
    () => createSectionSlotToRulesMapping(allUserReqRules),
    [allUserReqRules],
  );

  // Create mappings id_object: base_id_object for sections, rules, and labels
  const sectionsBaseIds = createBaseIdMapping(
    allSections,
    'id_section',
    'base_id_section',
  );
  const rulesBaseIds = createBaseIdMapping(allRules, 'id_rule', 'base_id_rule');
  const labelsBaseIds = createBaseIdMapping(
    allSlotLabels,
    'id_slot_label',
    'base_id_slot_label',
  );

  // Create mappings of base object ids (rules, sections, labels) to their most recent version
  const latestRules = createLatestBaseObjectMapping(
    allRules,
    'id_rule',
    'base_id_rule',
  );
  const latestSections = createLatestBaseObjectMapping(
    allSections,
    'id_section',
    'base_id_section',
  );
  const latestLabels = createLatestBaseObjectMapping(
    allSlotLabels,
    'id_slot_label',
    'base_id_slot_label',
  );

  const userShiftStats: UserStats = useMemo(
    () =>
      buildUserStats(
        allSectionSlots,
        allShiftAssignments,
        labelsBaseIds,
        latestLabels,
        latestRules,
        latestSections,
        rulesBaseIds,
        sectionsBaseIds,
        sectionSlotToRules,
      ),
    [
      allSectionSlots,
      allShiftAssignments,
      labelsBaseIds,
      latestLabels,
      latestRules,
      latestSections,
      rulesBaseIds,
      sectionsBaseIds,
      sectionSlotToRules,
    ],
  );

  const totalShifts = Object.entries(userShiftStats.shiftsPerSection).reduce(
    (acc, [_, data]) => acc + data.count,
    0,
  );
  const totalHours = Object.entries(userShiftStats.shiftsPerSection).reduce(
    (acc, [_, data]) => acc + data.hours,
    0,
  );

  // this will return an array of days Su-Sa
  const days = getWeekdayInitials(t, 'en');

  const percentages = useMemo(
    () => calculateUserPercentages(selectedItrs, itrs),
    [selectedItrs, itrs],
  );

  return (
    <Wrapper>
      <div className="px-4 sm:px-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold text-gray-900">
              {t('user.stats.stats')}
            </h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-4">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                {chain_name}
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {Object.entries(chainStats).map(([idChain, chainInfo]) => (
                    <Menu.Item key={idChain}>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            setSelectedItrs(
                              new Set(Object.keys(chainInfo.itrs).map(Number)),
                            );
                            setSelectedChain(Number(idChain));
                          }}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-900 w-full text-left',
                          )}
                        >
                          {chainInfo.chain_name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Menu>

            <Popover as="div" className="relative inline-block text-left">
              <div>
                <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <span>{t('generic.iterations')}</span>
                  {Object.keys(itrs).length === selectedItrs.size ? (
                    <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                      {t('generic.all')}
                    </span>
                  ) : null}
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Popover.Button>
              </div>

              <Popover.Panel className="absolute right-0 z-30 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none">
                <form className="space-y-4">
                  {Object.entries(itrs).map(([idItr, itrInfo]) => (
                    <div key={idItr} className="flex items-center">
                      <input
                        id={`filter-${idItr}`}
                        name={`${idItr}[]`}
                        defaultValue={idItr}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={selectedItrs.has(Number(idItr))}
                        onChange={() =>
                          toggleItrSelection(
                            Number(idItr),
                            selectedItrs,
                            setSelectedItrs,
                          )
                        }
                      />
                      <label
                        htmlFor={`filter-${idItr}`}
                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                      >
                        {parseIterationDates(
                          itrInfo.itr.start_day,
                          itrInfo.itr.end_day,
                          itrInfo.itr.itr_type,
                        )}
                      </label>
                    </div>
                  ))}
                </form>
              </Popover.Panel>
            </Popover>
          </div>
        </div>

        {/* Statistics Overview */}
        <div className="mt-4 flow-root">
          <div className="overflow-hidden">
            <div className="bg-white">
              {/* Summary Stats */}
              <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    {t('user.stats.totalShifts')}
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                    {totalShifts}
                  </dd>
                </div>
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    {t('user.stats.totalHours')}
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                    {totalHours}
                  </dd>
                </div>
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    {t('user.stats.pointsRespected')}
                  </dt>
                  <dd className="mt-1 flex justify-around gap-4">
                    <span className="text-3xl font-semibold tracking-tight text-green-600">
                      {percentages.positivePercentage !== null
                        ? `${percentages.positivePercentage}%`
                        : '-'}
                    </span>
                    <span className="text-3xl font-semibold tracking-tight text-red-600">
                      {percentages.negativePercentage !== null
                        ? `${percentages.negativePercentage}%`
                        : '-'}
                    </span>
                  </dd>
                </div>
              </dl>

              {/* Detailed Stats Section with Table and Graph Side by Side */}
              <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8 p-2">
                {/* Left side: Stats Table */}
                <div className="space-y-6">
                  {/* Services Table */}
                  <div>
                    <h2 className="font-semibold text-xl text-gray-800 mb-4">
                      {t('user.stats.shiftsPerService')}
                    </h2>
                    <div className="overflow-hidden shadow-md rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                              {t('generic.name')}
                            </th>
                            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                              {t('user.stats.numberOfShifts')}
                            </th>
                            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                              {t('user.stats.numberOfHours')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {Object.entries(userShiftStats.shiftsPerSection).map(
                            ([sectionId, data]) => (
                              <tr key={sectionId}>
                                <td className="py-2 pl-4 pr-3 text-sm">
                                  <div className="flex items-center">
                                    <div
                                      className="h-3 w-3 rounded-full mr-2"
                                      style={{
                                        backgroundColor: returnColor(
                                          allSections[Number(sectionId)].color,
                                          400,
                                        ),
                                      }}
                                    />
                                    {allSections[Number(sectionId)].name}
                                  </div>
                                </td>
                                <td className="px-3 py-2 text-sm text-center">
                                  {data.count}
                                </td>
                                <td className="px-3 py-2 text-sm text-center">
                                  {data.hours}
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Labels Table */}
                  <div>
                    <h2 className="font-semibold text-xl text-gray-800 mb-4">
                      {t('user.stats.shiftsPerLabel')}
                    </h2>
                    <div className="overflow-hidden shadow-md rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                              {t('generic.name')}
                            </th>
                            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                              {t('user.stats.numberOfShifts')}
                            </th>
                            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                              {t('user.stats.numberOfHours')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {Object.entries(userShiftStats.shiftsPerLabel).map(
                            ([labelId, data]) => (
                              <tr key={labelId}>
                                <td className="py-2 pl-4 pr-3 text-sm">
                                  <div className="flex items-center">
                                    <LabelIconComponent
                                      icon={allSlotLabels[Number(labelId)].icon}
                                      className="h-4 w-4 mr-2"
                                    />
                                    {allSlotLabels[Number(labelId)].name}
                                  </div>
                                </td>
                                <td className="px-3 py-2 text-sm text-center">
                                  {data.count}
                                </td>
                                <td className="px-3 py-2 text-sm text-center">
                                  {data.hours}
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Rules Table */}
                  <div>
                    <h2 className="font-semibold text-xl text-gray-800 mb-4">
                      {t('user.stats.shiftsPerRule')}
                    </h2>
                    <div className="overflow-hidden shadow-md rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                              {t('generic.name')}
                            </th>
                            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                              {t('user.stats.numberOfShifts')}
                            </th>
                            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                              {t('user.stats.numberOfHours')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {Object.entries(userShiftStats.shiftsPerRule).map(
                            ([ruleId, data]) => (
                              <tr key={ruleId}>
                                <td className="py-2 pl-4 pr-3 text-sm">
                                  {allRules[Number(ruleId)].name}
                                </td>
                                <td className="px-3 py-2 text-sm text-center">
                                  {data.count}
                                </td>
                                <td className="px-3 py-2 text-sm text-center">
                                  {data.hours}
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Counters Table */}
                  {Object.entries(userShiftStats.customCounters).length > 0 && (
                    <div>
                      <h2 className="font-semibold text-xl text-gray-800 mb-4">
                        {t('user.stats.counters')}
                      </h2>
                      <div className="overflow-hidden shadow-md rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                {t('generic.name')}
                              </th>
                              <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                {t('generic.value')}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {Object.entries(userShiftStats.customCounters).map(
                              ([counterId, value]) => (
                                <tr key={counterId}>
                                  <td className="py-2 pl-4 pr-3 text-sm">
                                    {counters[Number(counterId)].name}
                                  </td>
                                  <td className="px-3 py-2 text-sm text-center">
                                    {value}
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>

                {/* Right side: Chart */}
                <div>
                  <UserStatsChart
                    shiftsPerSection={userShiftStats.shiftsPerSection}
                    shiftsPerRule={userShiftStats.shiftsPerRule}
                    shiftsPerLabel={userShiftStats.shiftsPerLabel}
                    filteredItrs={Object.entries(itrs)
                      .filter(([idItr, _]) => selectedItrs.has(Number(idItr)))
                      .reduce(
                        (acc, [idItr, itrData]) => {
                          acc[Number(idItr)] = itrData.itr;
                          return acc;
                        },
                        {} as Record<number, (typeof itrs)[number]['itr']>,
                      )}
                    allSections={latestSections}
                    allRules={latestRules}
                    allLabels={latestLabels}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
