"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionStatus = exports.CounterUnit = exports.Country = exports.AlgExecutionStatus = exports.AlgErrorType = exports.Industry = exports.GroupManagerLevel = exports.ClientSourcingOrigin = exports.MedicalSpecialty = exports.ExchangeResponseType = exports.ShiftAssignmentType = exports.WorkerTaskStatus = exports.ItrResolution = exports.ItrType = exports.ItrStatuses = exports.EventStatus = exports.EventType = exports.UserEventType = exports.ExchangeResponseStatus = exports.ExchangeRequestStatus = exports.UserPreferenceType = exports.IncompatibilityType = exports.UserRequirementType = exports.RuleTypes = exports.GroupStatus = void 0;
var GroupStatus;
(function (GroupStatus) {
    GroupStatus["POTENTIAL"] = "POTENTIAL";
    GroupStatus["ONBOARDING"] = "ONBOARDING";
    GroupStatus["ACTIVE"] = "ACTIVE";
    GroupStatus["CHURNED"] = "CHURNED";
    GroupStatus["GHOST"] = "GHOST";
})(GroupStatus || (exports.GroupStatus = GroupStatus = {}));
var RuleTypes;
(function (RuleTypes) {
    RuleTypes["SECTION_USER_REQS"] = "SECTION_USER_REQS";
    RuleTypes["SLOT_LABEL_USER_REQS"] = "SLOT_LABEL_USER_REQS";
    RuleTypes["CUSTOM_USER_REQS"] = "CUSTOM_USER_REQS";
    RuleTypes["SINGLE_GROUP_INCOMP"] = "SINGLE_GROUP_INCOMP";
    RuleTypes["CROSS_GROUP_INCOMP"] = "CROSS_GROUP_INCOMP";
    RuleTypes["CROSS_SLOT_NEED"] = "CROSS_SLOT_NEED";
})(RuleTypes || (exports.RuleTypes = RuleTypes = {}));
var UserRequirementType;
(function (UserRequirementType) {
    UserRequirementType["SLOTS"] = "SLOTS";
    UserRequirementType["DURATION"] = "DURATION";
})(UserRequirementType || (exports.UserRequirementType = UserRequirementType = {}));
var IncompatibilityType;
(function (IncompatibilityType) {
    IncompatibilityType["SINGLE_GROUP_INCOMP"] = "SINGLE_GROUP_INCOMP";
    IncompatibilityType["CROSS_GROUP_INCOMP"] = "CROSS_GROUP_INCOMP";
})(IncompatibilityType || (exports.IncompatibilityType = IncompatibilityType = {}));
// if changed also change in translation.json(s)
var UserPreferenceType;
(function (UserPreferenceType) {
    UserPreferenceType["POINTS"] = "P";
    UserPreferenceType["JUSTIFIED_BLOCKING"] = "JB";
    UserPreferenceType["PERSONAL_BLOCKING"] = "PB";
})(UserPreferenceType || (exports.UserPreferenceType = UserPreferenceType = {}));
// if changed also change in translation.json(s)
var ExchangeRequestStatus;
(function (ExchangeRequestStatus) {
    ExchangeRequestStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ExchangeRequestStatus["CANCELED"] = "CANCELED";
    ExchangeRequestStatus["CANCELED_SYSTEM"] = "CANCELED_SYSTEM";
    ExchangeRequestStatus["COMPLETED"] = "COMPLETED";
})(ExchangeRequestStatus || (exports.ExchangeRequestStatus = ExchangeRequestStatus = {}));
var ExchangeResponseStatus;
(function (ExchangeResponseStatus) {
    ExchangeResponseStatus["PENDING_MANAGER"] = "PENDING_MANAGER";
    ExchangeResponseStatus["PENDING_DOCTOR"] = "PENDING_DOCTOR";
    ExchangeResponseStatus["APPROVED"] = "APPROVED";
    ExchangeResponseStatus["REJECTED_MANAGER"] = "REJECTED_MANAGER";
    ExchangeResponseStatus["REJECTED_DOCTOR"] = "REJECTED_DOCTOR";
    ExchangeResponseStatus["CANCELED"] = "CANCELED";
    ExchangeResponseStatus["CANCELED_SYSTEM"] = "CANCELED_SYSTEM";
})(ExchangeResponseStatus || (exports.ExchangeResponseStatus = ExchangeResponseStatus = {}));
var UserEventType;
(function (UserEventType) {
    UserEventType["EVENT"] = "E";
    UserEventType["VACATION"] = "V";
})(UserEventType || (exports.UserEventType = UserEventType = {}));
// change in translation.json if changed
var EventType;
(function (EventType) {
    EventType["HOLIDAY"] = "HOLIDAY";
    // EVENT = "EVENT",
    EventType["SEMINAR"] = "SEMINAR";
    EventType["PRACTICAL_TRAINING"] = "PRACTICAL_TRAINING";
    EventType["EXAM"] = "EXAM";
    EventType["OTHER"] = "OTHER";
})(EventType || (exports.EventType = EventType = {}));
// change in translation.json if changed
var EventStatus;
(function (EventStatus) {
    EventStatus["PENDING"] = "PENDING_APPROVAL";
    EventStatus["APPROVED"] = "APPROVED";
    EventStatus["REJECTED"] = "REJECTED";
})(EventStatus || (exports.EventStatus = EventStatus = {}));
var ItrStatuses;
(function (ItrStatuses) {
    ItrStatuses["INITIALIZATION"] = "INITIALIZATION";
    ItrStatuses["CONFIGURATION"] = "CONFIGURATION";
    ItrStatuses["RUNNING_SMARTASS"] = "RUNNING_SMARTASS";
    ItrStatuses["ASSIGNMENT"] = "ASSIGNMENT";
    ItrStatuses["PUBLISHED"] = "PUBLISHED";
    ItrStatuses["CLOSED"] = "CLOSED";
    ItrStatuses["ARCHIVED"] = "ARCHIVED";
})(ItrStatuses || (exports.ItrStatuses = ItrStatuses = {}));
var ItrType;
(function (ItrType) {
    ItrType["WEEK"] = "WEEK";
    ItrType["MONTH"] = "MONTH";
    ItrType["ABSTRACT"] = "ABSTRACT";
    ItrType["CALENDAR"] = "CALENDAR";
    ItrType["MONTHS_2"] = "2MONTHS";
    ItrType["MONTHS_6"] = "6MONTHS";
})(ItrType || (exports.ItrType = ItrType = {}));
var ItrResolution;
(function (ItrResolution) {
    ItrResolution[ItrResolution["DAY"] = 24] = "DAY";
    ItrResolution[ItrResolution["HOURS_12"] = 12] = "HOURS_12";
    ItrResolution[ItrResolution["HOURS_8"] = 8] = "HOURS_8";
    ItrResolution[ItrResolution["HOURS_6"] = 6] = "HOURS_6";
})(ItrResolution || (exports.ItrResolution = ItrResolution = {}));
var WorkerTaskStatus;
(function (WorkerTaskStatus) {
    WorkerTaskStatus["ENQUEUED"] = "enqueued";
    WorkerTaskStatus["RUNNING"] = "running";
    WorkerTaskStatus["SUCCESS"] = "success";
    WorkerTaskStatus["FAILED"] = "failed";
    WorkerTaskStatus["CANCELLED"] = "cancelled";
})(WorkerTaskStatus || (exports.WorkerTaskStatus = WorkerTaskStatus = {}));
var ShiftAssignmentType;
(function (ShiftAssignmentType) {
    // Pre-assigned
    ShiftAssignmentType["MANAGER"] = "MANAGER";
    // Assigned by the algorithm
    ShiftAssignmentType["ALGORITHM"] = "ALGORITHM";
    // Result of an exchange
    ShiftAssignmentType["EXCHANGED"] = "EXCHANGED";
    // Self-assigned
    ShiftAssignmentType["USER"] = "USER";
})(ShiftAssignmentType || (exports.ShiftAssignmentType = ShiftAssignmentType = {}));
var ExchangeResponseType;
(function (ExchangeResponseType) {
    ExchangeResponseType["ONE_FOR_ONE"] = "ONE_FOR_ONE";
    ExchangeResponseType["ONE_FOR_ZERO"] = "ONE_FOR_ZERO";
})(ExchangeResponseType || (exports.ExchangeResponseType = ExchangeResponseType = {}));
// This type is used in the eventsByDate object (ManualAssignment.tsx)
var MedicalSpecialty;
(function (MedicalSpecialty) {
    MedicalSpecialty["ALLERGY"] = "allergy";
    MedicalSpecialty["CLINICAL_ANALYSIS"] = "clinical_analysis";
    MedicalSpecialty["PATHOLOGICAL_ANATOMY"] = "pathological_anatomy";
    MedicalSpecialty["ANESTHESIOLOGY"] = "anesthesiology";
    MedicalSpecialty["VASCULAR_SURGERY"] = "vascular_surgery";
    MedicalSpecialty["DIGESTIVE_SYSTEM"] = "digestive_system";
    MedicalSpecialty["CLINICAL_BIOCHEMISTRY"] = "clinical_biochemistry";
    MedicalSpecialty["CARDIOLOGY"] = "cardiology";
    MedicalSpecialty["CARDIOVASCULAR_SURGERY"] = "cardiovascular_surgery";
    MedicalSpecialty["GENERAL_SURGERY"] = "general_surgery";
    MedicalSpecialty["ORAL_MAXILLOFACIAL_SURGERY"] = "oral_maxillofacial_surgery";
    MedicalSpecialty["ORTHOPEDIC_TRAUMATOLOGY_SURGERY"] = "orthopedic_traumatology_surgery";
    MedicalSpecialty["PEDIATRIC_SURGERY"] = "pediatric_surgery";
    MedicalSpecialty["PLASTIC_AESTHETIC_RECONSTRUCTIVE_SURGERY"] = "plastic_aesthetic_reconstructive_surgery";
    MedicalSpecialty["THORACIC_SURGERY"] = "thoracic_surgery";
    MedicalSpecialty["DERMATOLOGY"] = "dermatology";
    MedicalSpecialty["ENDOCRINOLOGY"] = "endocrinology";
    MedicalSpecialty["CLINICAL_PHARMACOLOGY"] = "clinical_pharmacology";
    MedicalSpecialty["GERIATRICS"] = "geriatrics";
    MedicalSpecialty["GYNECOLOGY"] = "gynecology";
    MedicalSpecialty["HEMATOLOGY"] = "hematology";
    MedicalSpecialty["IMMUNOLOGY"] = "immunology";
    MedicalSpecialty["OCCUPATIONAL_MEDICINE"] = "occupational_medicine";
    MedicalSpecialty["FAMILY_MEDICINE"] = "family_medicine";
    MedicalSpecialty["INTENSIVE_MEDICINE"] = "intensive_medicine";
    MedicalSpecialty["INTERNAL_MEDICINE"] = "internal_medicine";
    MedicalSpecialty["NUCLEAR_MEDICINE"] = "nuclear_medicine";
    MedicalSpecialty["MICROBIOLOGY"] = "microbiology";
    MedicalSpecialty["NEPHROLOGY"] = "nephrology";
    MedicalSpecialty["PULMONOLOGY"] = "pulmonology";
    MedicalSpecialty["NEUROSURGERY"] = "neurosurgery";
    MedicalSpecialty["CLINICAL_NEUROPHYSIOLOGY"] = "clinical_neurophysiology";
    MedicalSpecialty["NEUROLOGY"] = "neurology";
    MedicalSpecialty["OPHTHALMOLOGY"] = "ophthalmology";
    MedicalSpecialty["MEDICAL_ONCOLOGY"] = "medical_oncology";
    MedicalSpecialty["RADIATION_ONCOLOGY"] = "radiation_oncology";
    MedicalSpecialty["OTORHINOLARYNGOLOGY"] = "otorhinolaryngology";
    MedicalSpecialty["PEDIATRICS"] = "pediatrics";
    MedicalSpecialty["PSYCHIATRY"] = "psychiatry";
    MedicalSpecialty["RADIOLOGY"] = "radiology";
    MedicalSpecialty["REHABILITATION"] = "rehabilitation";
    MedicalSpecialty["RHEUMATOLOGY"] = "rheumatology";
    MedicalSpecialty["PUBLIC_HEALTH"] = "public_health";
    MedicalSpecialty["UROLOGY"] = "urology";
    MedicalSpecialty["URGENT_CARE"] = "urgent_care";
    MedicalSpecialty["OTHERS"] = "others";
})(MedicalSpecialty || (exports.MedicalSpecialty = MedicalSpecialty = {}));
var ClientSourcingOrigin;
(function (ClientSourcingOrigin) {
    ClientSourcingOrigin["UNKNOWN"] = "UNKNOWN";
    ClientSourcingOrigin["OUTREACH"] = "OUTREACH";
    ClientSourcingOrigin["BROWSER"] = "BROWSER";
    ClientSourcingOrigin["REFERRAL"] = "REFERRAL";
    ClientSourcingOrigin["HOSPITAL_MANAGEMENT"] = "HOSPITAL_MANAGEMENT";
    ClientSourcingOrigin["SOCIAL_MEDIA"] = "SOCIAL_MEDIA";
    ClientSourcingOrigin["OTHER"] = "OTHER";
})(ClientSourcingOrigin || (exports.ClientSourcingOrigin = ClientSourcingOrigin = {}));
var GroupManagerLevel;
(function (GroupManagerLevel) {
    GroupManagerLevel[GroupManagerLevel["READ_ONLY"] = 0] = "READ_ONLY";
    GroupManagerLevel[GroupManagerLevel["PAYMENT_ACCESS"] = 90] = "PAYMENT_ACCESS";
    GroupManagerLevel[GroupManagerLevel["FULL_ACCESS"] = 100] = "FULL_ACCESS";
})(GroupManagerLevel || (exports.GroupManagerLevel = GroupManagerLevel = {}));
var Industry;
(function (Industry) {
    Industry["HEALTHCARE"] = "HEALTHCARE";
    Industry["HOSPITALITY"] = "HOSPITALITY";
    Industry["LOGISTICS"] = "LOGISTICS";
    Industry["RETAIL"] = "RETAIL";
    Industry["OTHER"] = "OTHER";
})(Industry || (exports.Industry = Industry = {}));
var AlgErrorType;
(function (AlgErrorType) {
    AlgErrorType["PRE_EXEC"] = "PRE_EXEC";
    AlgErrorType["INCONSISTENT_RESULT"] = "INCONSISTENT_RESULT";
    AlgErrorType["BASE_DATA"] = "BASE_DATA";
    AlgErrorType["INFEASIBLE"] = "INFEASIBLE";
    AlgErrorType["INFEASIBLE_RELAXED"] = "INFEASIBLE_RELAXED";
    AlgErrorType["GENERAL"] = "GENERAL";
})(AlgErrorType || (exports.AlgErrorType = AlgErrorType = {}));
var AlgExecutionStatus;
(function (AlgExecutionStatus) {
    AlgExecutionStatus["SUCCESS"] = "SUCCESS";
    AlgExecutionStatus["FAILED"] = "FAILED";
})(AlgExecutionStatus || (exports.AlgExecutionStatus = AlgExecutionStatus = {}));
var Country;
(function (Country) {
    Country["AF"] = "AF";
    Country["AL"] = "AL";
    Country["DZ"] = "DZ";
    Country["AD"] = "AD";
    Country["AO"] = "AO";
    Country["AG"] = "AG";
    Country["AR"] = "AR";
    Country["AM"] = "AM";
    Country["AU"] = "AU";
    Country["AT"] = "AT";
    Country["AZ"] = "AZ";
    Country["BS"] = "BS";
    Country["BH"] = "BH";
    Country["BD"] = "BD";
    Country["BB"] = "BB";
    Country["BY"] = "BY";
    Country["BE"] = "BE";
    Country["BZ"] = "BZ";
    Country["BJ"] = "BJ";
    Country["BT"] = "BT";
    Country["BO"] = "BO";
    Country["BA"] = "BA";
    Country["BW"] = "BW";
    Country["BR"] = "BR";
    Country["BN"] = "BN";
    Country["BG"] = "BG";
    Country["BF"] = "BF";
    Country["BI"] = "BI";
    Country["KH"] = "KH";
    Country["CM"] = "CM";
    Country["CA"] = "CA";
    Country["CV"] = "CV";
    Country["CF"] = "CF";
    Country["TD"] = "TD";
    Country["CL"] = "CL";
    Country["CN"] = "CN";
    Country["CO"] = "CO";
    Country["KM"] = "KM";
    Country["CG"] = "CG";
    Country["CD"] = "CD";
    Country["CR"] = "CR";
    Country["CI"] = "CI";
    Country["HR"] = "HR";
    Country["CU"] = "CU";
    Country["CY"] = "CY";
    Country["CZ"] = "CZ";
    Country["DK"] = "DK";
    Country["DJ"] = "DJ";
    Country["DM"] = "DM";
    Country["DO"] = "DO";
    Country["EC"] = "EC";
    Country["EG"] = "EG";
    Country["SV"] = "SV";
    Country["GQ"] = "GQ";
    Country["ER"] = "ER";
    Country["EE"] = "EE";
    Country["SZ"] = "SZ";
    Country["ET"] = "ET";
    Country["FJ"] = "FJ";
    Country["FI"] = "FI";
    Country["FR"] = "FR";
    Country["GA"] = "GA";
    Country["GM"] = "GM";
    Country["GE"] = "GE";
    Country["DE"] = "DE";
    Country["GH"] = "GH";
    Country["GR"] = "GR";
    Country["GD"] = "GD";
    Country["GT"] = "GT";
    Country["GN"] = "GN";
    Country["GW"] = "GW";
    Country["GY"] = "GY";
    Country["HT"] = "HT";
    Country["HN"] = "HN";
    Country["HU"] = "HU";
    Country["IS"] = "IS";
    Country["IN"] = "IN";
    Country["ID"] = "ID";
    Country["IR"] = "IR";
    Country["IQ"] = "IQ";
    Country["IE"] = "IE";
    Country["IL"] = "IL";
    Country["IT"] = "IT";
    Country["JM"] = "JM";
    Country["JP"] = "JP";
    Country["JO"] = "JO";
    Country["KZ"] = "KZ";
    Country["KE"] = "KE";
    Country["KI"] = "KI";
    Country["KP"] = "KP";
    Country["KR"] = "KR";
    Country["KW"] = "KW";
    Country["KG"] = "KG";
    Country["LA"] = "LA";
    Country["LV"] = "LV";
    Country["LB"] = "LB";
    Country["LS"] = "LS";
    Country["LR"] = "LR";
    Country["LY"] = "LY";
    Country["LI"] = "LI";
    Country["LT"] = "LT";
    Country["LU"] = "LU";
    Country["MG"] = "MG";
    Country["MW"] = "MW";
    Country["MY"] = "MY";
    Country["MV"] = "MV";
    Country["ML"] = "ML";
    Country["MT"] = "MT";
    Country["MH"] = "MH";
    Country["MR"] = "MR";
    Country["MU"] = "MU";
    Country["MX"] = "MX";
    Country["FM"] = "FM";
    Country["MD"] = "MD";
    Country["MC"] = "MC";
    Country["MN"] = "MN";
    Country["ME"] = "ME";
    Country["MA"] = "MA";
    Country["MZ"] = "MZ";
    Country["MM"] = "MM";
    Country["NA"] = "NA";
    Country["NR"] = "NR";
    Country["NP"] = "NP";
    Country["NL"] = "NL";
    Country["NZ"] = "NZ";
    Country["NI"] = "NI";
    Country["NE"] = "NE";
    Country["NG"] = "NG";
    Country["MK"] = "MK";
    Country["NO"] = "NO";
    Country["OM"] = "OM";
    Country["PK"] = "PK";
    Country["PW"] = "PW";
    Country["PS"] = "PS";
    Country["PA"] = "PA";
    Country["PG"] = "PG";
    Country["PY"] = "PY";
    Country["PE"] = "PE";
    Country["PH"] = "PH";
    Country["PL"] = "PL";
    Country["PT"] = "PT";
    Country["QA"] = "QA";
    Country["RO"] = "RO";
    Country["RU"] = "RU";
    Country["RW"] = "RW";
    Country["KN"] = "KN";
    Country["LC"] = "LC";
    Country["VC"] = "VC";
    Country["WS"] = "WS";
    Country["SM"] = "SM";
    Country["ST"] = "ST";
    Country["SA"] = "SA";
    Country["SN"] = "SN";
    Country["RS"] = "RS";
    Country["SC"] = "SC";
    Country["SL"] = "SL";
    Country["SG"] = "SG";
    Country["SK"] = "SK";
    Country["SI"] = "SI";
    Country["SB"] = "SB";
    Country["SO"] = "SO";
    Country["ZA"] = "ZA";
    Country["SS"] = "SS";
    Country["ES"] = "ES";
    Country["LK"] = "LK";
    Country["SD"] = "SD";
    Country["SR"] = "SR";
    Country["SE"] = "SE";
    Country["CH"] = "CH";
    Country["SY"] = "SY";
    Country["TJ"] = "TJ";
    Country["TZ"] = "TZ";
    Country["TH"] = "TH";
    Country["TL"] = "TL";
    Country["TG"] = "TG";
    Country["TO"] = "TO";
    Country["TT"] = "TT";
    Country["TN"] = "TN";
    Country["TR"] = "TR";
    Country["TM"] = "TM";
    Country["TV"] = "TV";
    Country["UG"] = "UG";
    Country["UA"] = "UA";
    Country["AE"] = "AE";
    Country["GB"] = "GB";
    Country["US"] = "US";
    Country["UY"] = "UY";
    Country["UZ"] = "UZ";
    Country["VU"] = "VU";
    Country["VA"] = "VA";
    Country["VE"] = "VE";
    Country["VN"] = "VN";
    Country["YE"] = "YE";
    Country["ZM"] = "ZM";
    Country["ZW"] = "ZW";
})(Country || (exports.Country = Country = {}));
var CounterUnit;
(function (CounterUnit) {
    CounterUnit["NO_UNIT"] = "NO_UNIT";
    CounterUnit["MINUTES"] = "MINUTES";
})(CounterUnit || (exports.CounterUnit = CounterUnit = {}));
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["INCOMPLETE"] = "incomplete";
    SubscriptionStatus["INCOMPLETE_EXPIRED"] = "incomplete_expired";
    SubscriptionStatus["ACTIVE"] = "active";
    SubscriptionStatus["TRIALING"] = "trialing";
    SubscriptionStatus["PAST_DUE"] = "past_due";
    SubscriptionStatus["CANCELED"] = "canceled";
    SubscriptionStatus["UNPAID"] = "unpaid";
    SubscriptionStatus["PAUSED"] = "paused";
})(SubscriptionStatus || (exports.SubscriptionStatus = SubscriptionStatus = {}));
