import React from 'react';
import {
  ColorIndex,
  ColorName,
  dateToString,
  LabelIcon,
  parseSectionSlotDateTime,
  returnColor,
} from '@youshift/shared/utils';

import LabelIconComponent from '../LabelIconComponent';

export interface AssignmentDetail {
  id_section_slot: number;
  start: string;
  end: string;
  icon: LabelIcon;
  shade: ColorIndex;
  color: ColorName;
  name: string;
  pointAward: number;
}

interface ShiftAssignmentBoxProps {
  assignment: AssignmentDetail;
}
export function ShiftAssignmentBox({ assignment }: ShiftAssignmentBoxProps) {
  const { id_section_slot, start, end, icon, shade, color, name, pointAward } =
    assignment;

  const startHours = dateToString(start, 'hh:mm');
  const endHours = dateToString(end, 'hh:mm');

  return (
    <div
      key={id_section_slot}
      className="rounded-md flex flex-col justify-between items-center p-1 border shadow-md relative"
      style={{
        backgroundColor: returnColor(color, shade),
        border: '1px solid gray',
      }}
    >
      {pointAward ? (
        <span className="w-4 h-4 absolute -top-1.5 -right-1.5 bg-green-600 rounded-xl flex items-center justify-center">
          <p className=" text-white text-xxxs text-center margin-auto align-middle ">
            {pointAward}
          </p>
        </span>
      ) : null}
      {/* Icon */}
      <LabelIconComponent icon={icon} className="w-4 h-4" />

      {/* Section Name */}
      <span className="font-semibold text-xs text-gray-800 whitespace-normal">
        {name}
      </span>

      {/* Time Range */}
      <div className="mt-2 text-xs text-gray-600">{`${startHours} - ${endHours}`}</div>
    </div>
  );
}
