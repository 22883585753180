import { Dialog, Transition } from '@headlessui/react';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useCreateFakeUserMutation } from '@youshift/shared/hooks/mutations';

import { YSButton } from '../Buttons';

export default function CreateFakeUsers({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const cancelButtonRef = useRef(null);
  const [names, setNames] = useState<string>('');
  const [numUsers, setNumUsers] = useState<number>(1);
  const [useNames, setUseNames] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const createFakeUserMutation = useCreateFakeUserMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['personnel'] });
      toast.success(t('manager.team.createFakeUsers.success'));
      setOpen(false);
    },
    // onError: error => {
    //   const { errorKey, context } = generateErrorStringFromError(
    //     error,
    //     t('manager.team.createFakeUsers.error'),
    //   );
    //   const errorString = t(errorKey, context);
    //   toast.error(errorString);
    // },
  });

  const handleCreate = () => {
    if (useNames) {
      createFakeUserMutation.mutate({ names });
    } else {
      const generatedNames = Array.from(
        { length: numUsers },
        (_, i) => `User${i + 1}`,
      ).join(',');
      createFakeUserMutation.mutate({ names: generatedNames });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserGroupIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title
                      as="h3"
                      className="text-base mb-1 font-semibold leading-6 text-gray-900"
                    >
                      {t('manager.team.createFakeUsers.createUsers')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="flex items-center mb-4">
                        <span className="mr-2 text-sm text-gray-900">
                          {useNames ? t('Use Names') : t('Use Number')}
                        </span>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={useNames}
                            onChange={() => setUseNames(!useNames)}
                            className="sr-only peer"
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                        </label>
                      </div>
                      {useNames ? (
                        <div className="isolate -space-y-px rounded-md shadow-sm mt-2">
                          <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                            <label
                              htmlFor="names"
                              className="block text-xs font-medium text-gray-900"
                            >
                              {t('Test Users (Separated by ",")')}
                            </label>
                            <input
                              type="text"
                              name="names"
                              id="names"
                              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              value={names}
                              onChange={e => setNames(e.target.value)}
                              placeholder={t('Test User 1, Test User 2 ...')}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="isolate -space-y-px rounded-md shadow-sm mt-2">
                          <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                            <label
                              htmlFor="numUsers"
                              className="block text-xs font-medium text-gray-900"
                            >
                              {t('Number of Users')}
                            </label>
                            <input
                              type="number"
                              name="numUsers"
                              id="numUsers"
                              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              value={numUsers}
                              onChange={e =>
                                setNumUsers(Number(e.target.value))
                              }
                              min={1}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Dialog.Title as="p" className="text-xs mb-4 text-gray-500">
                  {t('manager.team.createFakeUsers.description')}
                </Dialog.Title>
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
                  <YSButton
                    classNames="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                    type="button"
                    onClick={handleCreate}
                    disabled={createFakeUserMutation.isPending}
                    loading={createFakeUserMutation.isPending}
                  >
                    {t('generic.create')}
                  </YSButton>
                  <YSButton
                    type="button"
                    variant="ghost-primary"
                    onClick={() => setOpen(false)}
                  >
                    {t('generic.cancel')}
                  </YSButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
